




 

  

  //alert(fecha_formateada);
  import moment from "moment";
import MainService from "@/services/MainService.js";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");
import Vue from "vue";
import VueLocalStorage from "vue-ls"
Vue.use(VueLocalStorage)

import html2pdf from 'html2pdf.js'

//importing bootstrap 5 and pdf maker Modules
import "bootstrap/dist/css/bootstrap.min.css";


export default {
    name: "BitacoraPage",
    data() {
        let ss = new MainService();
        return {
            index: 1,
           
            date2: new Date(),
            picker: new Date().toISOString().substr(0, 0),
           
           
            msg: "BitacoraPage",
            ss: ss,
            
            ajax: {
                "url": ss.indexFase(),
                headers: ss.getToken(),
            },
           
            Estudiantes: [],
            selectedHitEst: 0,
            Fases: [],
            image:'',
            Proyectos: [],
            isLoading: false,
            errorBag: {},
          
            auth : null,
             bitacora: {
                Estudiante:"",
             },
        };
    },
    methods: {
        cambioOpciones2(){

            
            if(document.getElementById('opciones2').value == 1) {
                document.getElementById('show').value='INTRODUCCIÓN';
                document.getElementById('show2').value='ANTECEDENTES';
                document.getElementById('show3').value='PROBLEMA PRINCIPAL';
                document.getElementById('show4').value='PROBLEMAS SECUNDARIOS';
                document.getElementById('show5').value='OBJETIVO GENERAL';
                document.getElementById('show6').value='OBJETIVOS SECUNDARIOS';
                document.getElementById('show7').value='JUSTIFICACIÓN';
                document.getElementById('show8').value='LÍMITES';
                document.getElementById('show9').value='ALCANCES';
                document.getElementById('show10').value='TEMARIO TENTATIVO';
                document.getElementById('show11').value='';
                document.getElementById('show12').value='';
                
            }
            if(document.getElementById('opciones2').value == 2) {
                document.getElementById('show').value='Título 2.1';
                document.getElementById('show2').value='Título 2.2';
                document.getElementById('show3').value='Título 2.3';
                document.getElementById('show4').value='Título 2.4';
                document.getElementById('show5').value='Título 2.5';
                document.getElementById('show6').value='Título 2.6';
                document.getElementById('show7').value='Título 2.7';
                document.getElementById('show8').value='Título 2.8';
                document.getElementById('show9').value='Título 2.9';
                document.getElementById('show10').value='Título 2.10';
                document.getElementById('show11').value='';
                document.getElementById('show12').value='';
            }

            if(document.getElementById('opciones2').value == 3) {
                document.getElementById('show').value='PLAN DE DESARROLLO DE SOFTWARE';
                document.getElementById('show2').value='ANALISIS DE LA SITUACION ACTUAL';
                document.getElementById('show3').value='METODOLOGÍA DE DESARROLLO';
                document.getElementById('show4').value='DISEÑO DE LA BASE DE DATOS';
                document.getElementById('show5').value='';
                document.getElementById('show6').value='';
                document.getElementById('show7').value='';
                document.getElementById('show8').value='';
                document.getElementById('show9').value='';
                document.getElementById('show10').value='';
                document.getElementById('show11').value='';
                document.getElementById('show12').value='';
            }
            if(document.getElementById('opciones2').value == 4) {
                document.getElementById('show').value='PLAN DE DESARROLLO DE SOFTWARE';
                document.getElementById('show2').value='ANALISIS DE LA SITUACION ACTUAL';
                document.getElementById('show3').value='METODOLOGÍA DE DESARROLLO';
                document.getElementById('show4').value='DISEÑO DE LA BASE DE DATOS';
                document.getElementById('show5').value='DIAGRAMAS UML';
                document.getElementById('show6').value='EVALUACIÓN TÉCNICA';
                document.getElementById('show7').value='EVALUACIÓN ECONÓMICA';
                document.getElementById('show8').value='';
                document.getElementById('show9').value='';
                document.getElementById('show10').value='';
                document.getElementById('show11').value='';
                document.getElementById('show12').value='';
            }
            
            if(document.getElementById('opciones2').value == 5) {
                document.getElementById('show').value='CARATULAS';
                document.getElementById('show2').value='INDICES';
                document.getElementById('show3').value='DEDICATORIA';
                document.getElementById('show4').value='AGRADECIMIENTOS';
                document.getElementById('show5').value='CONCLUSIONES Y RECOMENDACIONES';
                document.getElementById('show6').value='RESUMEN EJECUTIVO';
                document.getElementById('show7').value='CAPITULO 1';
                document.getElementById('show8').value='CAPITULO 2';
                document.getElementById('show9').value='CAPITULO 3';
                document.getElementById('show10').value='CAPITULO 4';
                document.getElementById('show11').value='CAPITULO 5';
                document.getElementById('show12').value='ANEXOS';
               
            }
            if(document.getElementById('opciones2').value == 6) {
                document.getElementById('show').value='CARATULAS';
                document.getElementById('show2').value='INDICES';
                document.getElementById('show3').value='DEDICATORIA';
                document.getElementById('show4').value='AGRADECIMIENTOS';
                document.getElementById('show5').value='CONCLUSIONES Y RECOMENDACIONES';
                document.getElementById('show6').value='RESUMEN EJECUTIVO';
                document.getElementById('show7').value='CAPITULO 1';
                document.getElementById('show8').value='CAPITULO 2';
                document.getElementById('show9').value='CAPITULO 3';
                document.getElementById('show10').value='CAPITULO 4';
                document.getElementById('show11').value='CAPITULO 5';
                document.getElementById('show12').value='ANEXOS';
            }
        },
        printDate: function () {
            return new Date().toLocaleDateString();
          },
        reRender(){
            this.index++;
         },
        getProyecto() {
            this.ss.listProyecto().then(
                (result) => {
                    let response = result.data;
                    this.Proyectos= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getFase() {
            this.ss.listFase().then(
                (result) => {
                    let response = result.data;
                    this.Fases= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        exportToPDF () {
            html2pdf(this.$refs.document, {
                margin: 0.4,
                filename: 'Bitacora.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { dpi: 192, letterRendering: true },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            })
        },

        firmar(){
            // let BtnEle = document.getElementById("firma");
            // let resEle = document.querySelector(".result");
            // BtnEle.addEventListener("click", () => {
            //     fetch(this.auth.URLFirma)
            //     .then(function (response) {
            //         return response.blob();
            //     })
            //     .then(function (blob) {
            //         resEle.innerHTML = "blob.size = " + blob.size + "<br>";
            //         resEle.innerHTML += "blob.type = " + blob.type + "<br>";
            //     });
            // });

            // let baseStr64=this.auth.Firma64;
            // return imgElem.setAttribute('src', "data:image/jpg;base64," + baseStr64);

            var image = new Image();
            image.src = 'data:image/png;base64,'+this.auth.Firma64;
            document.body.appendChild(image);
        },
        handleImage(e){
            const selectedImage = e.target.files[0];
            this.createBase64Image(selectedImage);


            
        },
        createBase64Image(fileObject) {
            const reader = new FileReader();

            reader.onload = (e)=> {
                this.image = e.target.result;
            };
            reader.readAsDataURL(fileObject);
        },
        
      getEstudiante() {
        this.ss.listEstudiante().then(
            (result) => {
                let response = result.data;
                this.Estudiantes= response.data;
            }
        ).catch(error => {
            console.log(error);
        });
    },
    showUsuario(id) {
        this.isLoading=true;
        this.ss.showUsuario(id).then(
            (result) => {
                let response = result.data;
                this.auth = response.data;
             
                this.isLoading=false;
            }
        ).catch(error => {
            console.log(error);
            this.isLoading=false;
        });
    },
       
    },
    components: {
       
        Loader,
      
    },
    filters: {
        moment(date) {
          return moment(date).format("DD-MM-YYYY");
        },
      },
      watch: {
       
        selectedHitEst(value) {
            this.bitacora.Estudiante = value.Estudiante;
           
            //console.log(value);
        },
    },
    mounted() {
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        
            this.getEstudiante();
            this.getFase();
            this.getProyecto();
            //this.auth = usuario;
            this.showUsuario(usuario.id);
            //console.log(this.auth.Firma64);
            Vue.ls.clear();

       
            
          
        }
    
};
