import moment from "moment";
import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "DocumentacionPage",
    data() {

        let ss = new MainService();
        return {
          
            date: new Date().toISOString().substr(0, 10),
            picker: new Date().toISOString().substr(0, 10),

            msg: "DocumentacionPage",
            ss: ss,
            ajax: {
                "url": ss.indexDocumentacion(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false},
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false },
                { data: 'Estudiante', name: 'e.Estudiante', title: 'Estudiante', width: '300px' },
                { data: 'TipoInforme', name: 'ti.TipoInforme', title: 'Tipo Informe'},
                { data: 'Fase', name: 'f.Fase', title: 'Fase', width: '300px'},
                {data: 'FechaEntrega', name: 'p.FechaEntrega', title: 'Fecha Entrega'},
                {data: 'Rol', name: 'p.Rol', title: 'Rol', visible: false},
                {data: 'Tutor', name: 'p.Tutor', title: 'Email',visible: false},
                {data: 'EmailDocente', name: 'p.EmailDocente', title: 'EmailDocente',visible: false},
                {data: 'CI', name: 'p.CI', orderable: false, searchable: false, visible: false},
                //{ data: 'Usuario', name: 'p.Usuario', title: 'Responsable' },
                
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
           
            TipoInformes: [],
            Fases: [],
            selectedHitEst: 0,
            selectedHitTutor : null,
            documentacion: {
                Estudiante: "",
                Tutor:"",
            },

            documentacion2: {},
            Estudiantes: [],
            entregas2: {},
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,

            ex7: "0",
            auth : null,
            isLoading: false,
            isLoadingFile:false,
            errorBag: {}
        };
    },
    methods: {
        aplicarFiltros(){
         
            let estu = this.documentacion   
            let tipo = this.documentacion.TipoInforme 
            let fase = this.documentacion.Fase
                    
            
            this.ajax.data = function(d){
                d.Estudiante = estu
                d.TipoInforme = tipo
                d.Fase = fase
                
            }
            //console.log("prueba");
            this.$refs['datatable-documentacion'].reloadAjax(this.ajax);
        },
        onEditorChange($event) {
            this.content = $event.html
          },
         printDate: function () {
            return new Date().toLocaleDateString();
          },
         getFase() {
            this.ss.listFase().then(
                (result) => {
                    let response = result.data;
                    this.Fases= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstudiante() {
            this.ss.listEstudiante().then(
                (result) => {
                    let response = result.data;
                    this.Estudiantes= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getUsuario() {
            this.ss.listUsuario().then(
                (result) => {
                    let response = result.data;
                    this.Usuarios= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getTipoInforme() {
            this.ss.listTipoInforme().then(
                (result) => {
                    let response = result.data;
                    this.TipoInformes= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        newDocumentacion() {
            this.documentacion = {};
            this.$refs['frm-documentacion'].show();
        },
        showDocumentacion(id) {
            this.isLoading=true;
            this.ss.showDocumentacion(id).then(
                (result) => {
                    let response = result.data;
                    this.documentacion = response.data;
                    this.$refs['view-documentacion'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editDocumentacion() {
            this.$refs['frm-edit'].show();
            this.$refs['view-documentacion'].hide();
        },
        cancelDocumentacion() {
            if (this.documentacion.id) {
                this.$refs['view-documentacion'].show();
            }
            
            this.$refs['frm-documentacion'].hide();
            this.$refs['view-documentacion'].hide();
            this.$refs['frm-edit'].hide();
        },
        saveDocumentacion() {

            if(this.auth.Rol==5){
                this.documentacion.Estudiante = this.entregas2.Estudiante;
                this.documentacion.EmailEstudiante = this.entregas2.EmailEstudiante;
                this.documentacion.EmailDocente = this.entregas2.EmailDocente;
                this.documentacion.CI = this.entregas2.CI;
            }
            

            this.ss.storeDocumentacion(this.documentacion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-documentacion'].hide();
                    this.$refs['datatable-documentacion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        update() {
         
            this.ss.update(this.documentacion).then(

              
                (result) => {
                    
                    console.log(result);
                   
                    let response = result.data;
                   
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        },
                        
                    )
                   
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
               
                    this.$refs['frm-edit'].hide();
                    this.$refs['datatable-documentacion'].reload();
                })
                .catch((error) => {
                    this.esBotonDeshabilitado = false,
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteDocumentacion() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                buttons: true,
                dangerMode: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                        this.ss.destroyDocumentacion(this.documentacion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-documentacion'].hide();
                                this.$refs['datatable-documentacion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
       
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
              // Validación del tipo de archivo
              if (input.files[0].type !== 'application/pdf') {
                this.$bvToast.toast(
                  'El archivo seleccionado no es un Documento PDF',
                  {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000
                  }
                );
                this.isLoadingFile = false;
                return;
              }
          
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]);
              var data = new FormData();
              data.append('File', input.files[0]);
              this.ss.uploadFile(data)
                .then(result => {
                  if (result.data.success) {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Correcto',
                        variant: 'info',
                        autoHideDelay: 5000
                      }
                    );
                    this.documentacion.DocumentoInforme = result.data.data;

                  } else {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 5000
                      }
                    )
                  }
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                });
            }
          }, 
          showEstudiante2(id) {
            //this.isLoading=true;
            this.ss.showEstudiante2(id).then(
                (result) => {
                    let response = result.data;
                    this.entregas2 = response.data;
                    //this.$refs['view-entregas'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

       /*    loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            this.documentacion.DocumentoInforme = result.data.data;

                            
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo el documento',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo el documento',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        }, */
       
        
        
        
        draw() {
            window.$('.btn-datatable-Documentacion').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showDocumentacion(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    watch: {
            selectedHitEst(value) {
            this.documentacion.Estudiante = value.Estudiante;
            this.documentacion.EmailEstudiante = value.EmailEstudiante;
            this.documentacion.EmailDocente = value.EmailDocente;
            this.documentacion.CI = value.CI;
            this.documentacion.UnidadAcademica = value.UnidadAcademica;
            //console.log(value);
        },
        selectedHitTutor(value) {
            this.documentacion.Tutor = value.email;
            //console.log(value);
        },
    },
    filters: {
        moment(date) {
          return moment(date).format("DD-MM-YYYY");
        },
      },
    mounted() {
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        console.log(usuario);
        if (!usuario) {
          this.$router.push('/Login');
        }else{
           
            this.getFase();
            this.auth = usuario;
            this.showEstudiante2(this.auth.id);
            this.getTipoInforme();
            this.getEstudiante();
            this.getUsuario();
            
        }
    }
};
