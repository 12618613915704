var axios = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        } else {
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    indexUsuario() {
        return axios.defaults.baseURL + 'api/Usuario/index';
    }

    showUsuario(id) {
        return axios.get('api/Usuario/show?id=' + id);
    }

    listUsuario() {
        return axios.get('api/Usuario/list');
    }
    listUEstudiante() {
        return axios.get('api/Usuario/listEstudiante');
    }

    listUDocente() {
        return axios.get('api/Usuario/listDocente');
    }

    listUTRR() {
        return axios.get('api/Usuario/listTRR');
    }


    
    printRevisiones(item){
        return axios.post('api/TipoReporte/printRevisiones', item)
    }

    printtggeneral(item){
        return axios.post('api/TipoReporte/printtggeneral', item)
    }

    printDefensa(item){
        return axios.post('api/TipoReporte/printDefensa', item)
    }

    printTR(item){
        return axios.post('api/TipoReporte/printTR', item)
    }

    printDR(item){
        return axios.post('api/TipoReporte/printDR', item)
    }
    
    printPE(item){
        return axios.post('api/TipoReporte/printPE', item)
    }


    listUsuario2() {
        return axios.get('api/Usuario/listUsuario');
    }


    storeUsuario(item) {
        return axios.post('api/Usuario/store', item);
    }

    destroyUsuario(item) {
        return axios.post('api/Usuario/destroy', item);
    }

    select2Usuario(q) {
        return axios.get('api/Usuario/select2?q=' + q);
    }

    changePassword(item) {
        return axios.post('api/Usuario/changePassword', item);
    }

    showUnidadAcademica(id) {
        return axios.get('api/UnidadAcademica/show?id=' + id);
    }

    indexUnidadAcademica() {
        return axios.defaults.baseURL + 'api/UnidadAcademica/index';
    }

    listUnidadAcademica() {
        return axios.get('api/UnidadAcademica/list');
    }

    storeUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/store', item);
    }

    destroyUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/destroy', item);
    }

    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    destroyRol(item) {
        return axios.post('api/Rol/destroy', item);
    }

    //TipoReporte
    showTipoReporte(id) {
        return axios.get('api/TipoReporte/show?id=' + id);
    }

    indexTipoReporte() {
        return axios.defaults.baseURL + 'api/TipoReporte/index';
    }

    listTipoReporte() {
        return axios.get('api/TipoReporte/list');
    }

    storeTipoReporte(item) {
        return axios.post('api/TipoReporte/store', item);
    }

    destroyTipoReporte(item) {
        return axios.post('api/TipoReporte/destroy', item);
    }

    generateTipoReporte(item) {
        return axios.post('api/TipoReporte/generate', item);
    }
    generateTipoReporte2(item) {
        return axios.post('api/TipoReporte/generate', item);
    }

    //Fase
    showFase(id) {
        return axios.get('api/Fase/show?id=' + id);
    }

    indexFase() {
        return axios.defaults.baseURL + 'api/Fase/index';
    }

    listFase() {
        return axios.get('api/Fase/list');
    }

    storeFase(item) {
        return axios.post('api/Fase/store', item);
    }

    destroyFase(item) {
        return axios.post('api/Fase/destroy', item);
    }

    //Funcion
    showFuncion(id) {
        return axios.get('api/Funcion/show?id=' + id);
    }

    indexFuncion() {
        return axios.defaults.baseURL + 'api/Funcion/index';
    }

    listFuncion() {
        return axios.get('api/Funcion/list');
    }

    storeFuncion(item) {
        return axios.post('api/Funcion/store', item);
    }

    destroyFuncion(item) {
        return axios.post('api/Funcion/destroy', item);
    }

  

    

    //Gestion
    showGestion(id) {
        return axios.get('api/Gestion/show?id=' + id);
    }

    indexGestion() {
        return axios.defaults.baseURL + 'api/Gestion/index';
    }

    listGestion() {
        return axios.get('api/Gestion/list');
    }

    storeGestion(item) {
        return axios.post('api/Gestion/store', item);
    }

    destroyGestion(item) {
        return axios.post('api/Gestion/destroy', item);
    }

    //Asignacion 
    showAsignacion(id) {
        return axios.get('api/Asignacion/show?id=' + id);
    }

    indexAsignacion() {
        return axios.defaults.baseURL + 'api/Asignacion/index';
    }

    listAsignacion() {
        return axios.get('api/Asignacion/list');
    }

    storeAsignacion(item) {
        return axios.post('api/Asignacion/store', item);
    }

    destroyAsignacion(item) {
        return axios.post('api/Asignacion/destroy', item);
    }


    //CriterioEvaluacion
    showCriterioEvaluacion(id) {
        return axios.get('api/CriterioEvaluacion/show?id=' + id);
    }

    indexCriterioEvaluacion() {
        return axios.defaults.baseURL + 'api/CriterioEvaluacion/index';
    }

    listCriterioEvaluacion() {
        return axios.get('api/CriterioEvaluacion/list');
    }

    storeCriterioEvaluacion(item) {
        return axios.post('api/CriterioEvaluacion/store', item);
    }

    destroyCriterioEvaluacion(item) {
        return axios.post('api/CriterioEvaluacion/destroy', item);
    }

    //Evaluacion
    showEvaluacion(id) {
        return axios.get('api/Evaluacion/show?id=' + id);
    }

    indexEvaluacion() {
        return axios.defaults.baseURL + 'api/Evaluacion/index';
    }

    lisEvaluacion() {
        return axios.get('api/Evaluacion/list');
    }

    storeEvaluacion(item) {
        return axios.post('api/Evaluacion/store', item);
    }

    destroyEvaluacion(item) {
        return axios.post('api/Evaluacion/destroy', item);
    }

    //AreaInvestigacion
    showAreaInvestigacion(id) {
        return axios.get('api/AreaInvestigacion/show?id=' + id);
    }

    indexAreaInvestigacion() {
        return axios.defaults.baseURL + 'api/AreaInvestigacion/index';
    }

    listAreaInvestigacion() {
        return axios.get('api/AreaInvestigacion/list');
    }

    storeAreaInvestigacion(item) {
        return axios.post('api/AreaInvestigacion/store', item);
    }

    destroyAreaInvestigacion(item) {
        return axios.post('api/AreaInvestigacion/destroy', item);
    }

    //LineaInvestigacion
    showLineaInvestigacion(id) {
        return axios.get('api/LineaInvestigacion/show?id=' + id);
    }

    indexLineaInvestigacion() {
        return axios.defaults.baseURL + 'api/LineaInvestigacion/index';
    }

    listLineaInvestigacion() {
        return axios.get('api/LineaInvestigacion/list');
    }

    storeLineaInvestigacion(item) {
        return axios.post('api/LineaInvestigacion/store', item);
    }

    destroyLineaInvestigacion(item) {
        return axios.post('api/LineaInvestigacion/destroy', item);
    }

    //Entregas
    showEntregas(id) {
        return axios.get('api/Entregas/show?id=' + id);
    }
    showEntregas2(estudiante) {
        return axios.get('api/Entregas/show2?Estudiante=' + estudiante);
    }

    indexEntregas() {
        return axios.defaults.baseURL + 'api/Entregas/index';
    }

    listEntregas() {
        return axios.get('api/Entregas/list');
    }

    storeEntregas(item) {
        return axios.post('api/Entregas/store', item);
    }

    destroyEntregas(item) {
        return axios.post('api/Entregas/destroy', item);
    }

    select2Entregas(q) {
        return axios.get('api/Entregas/select2?q=' + q);
    }
    updateEntregas2(item) {
        return axios.post('api/Entregas/update2', item);
    }
    updateEntregas3(item) {
        return axios.post('api/Entregas/update3', item);
    }
    updateEntregas4(item) {
        return axios.post('api/Entregas/update4', item);
    }
    updateEntregas55(item) {
        return axios.post('api/Entregas/update55', item);
    }
    
    


     //*************************** AdmEntregas */
     showAdmEntregas(id) {
        return axios.get('api/AdmEntregas/show?id=' + id);
    }

    indexAdmEntregas() {
        return axios.defaults.baseURL + 'api/AdmEntregas/index';
    }

    listAdmEntregas() {
        return axios.get('api/AdmEntregas/list');
    }

    storeAdmEntregas(item) {
        return axios.post('api/AdmEntregas/store', item);
    }

    destroyAdmEntregas(item) {
        return axios.post('api/AdmEntregas/destroy', item);
    }





    //*************************** Semestre */
    showSemestre(id) {
        return axios.get('api/Semestre/show?id=' + id);
    }

    indexSemestre() {
        return axios.defaults.baseURL + 'api/Semestre/index';
    }

    listSemestre() {
        return axios.get('api/Semestre/list');
    }

    storeSemestre(item) {
        return axios.post('api/Semestre/store', item);
    }

    destroySemestre(item) {
        return axios.post('api/Semestre/destroy', item);
    }

     //*************************** Especialidad */
     showEspecialidad(id) {
        return axios.get('api/Especialidad/show?id=' + id);
    }
    
    indexEspecialidad() {
        return axios.defaults.baseURL + 'api/Especialidad/index';
    }
    
    listEspecialidad() {
        return axios.get('api/Especialidad/list');
    }
    
    storeEspecialidad(item) {
        return axios.post('api/Especialidad/store', item);
    }
    
    destroyEspecialidad(item) {
        return axios.post('api/Especialidad/destroy', item);
    }
    listarEspecialidad() {
        return axios.get('api/Especialidad/listarEspecialidad');
    }

    //*************************** Nivel Academico */
    showNivelAcademico(id) {
        return axios.get('api/NivelAcademico/show?id=' + id);
    }

    indexNivelAcademico() {
        return axios.defaults.baseURL + 'api/NivelAcademico/index';
    }

    listNivelAcademico() {
        return axios.get('api/NivelAcademico/list');
    }

    storeNivelAcademico(item) {
        return axios.post('api/NivelAcademico/store', item);
    }

    destroyNivelAcademico(item) {
        return axios.post('api/NivelAcademico/destroy', item);
    }
    listarNAcademico() {
        return axios.get('api/NivelAcademico/listarNAcademico');
    }

    




    showEstudiante(id) {
        return axios.get('api/Estudiante/show?id=' + id);
    }
    showEstudiante2(id) {
        return axios.get('api/Estudiante/show2?id=' + id);
    }
    showEstudianteE(estudiante) {
        return axios.get('api/Estudiante/showE?Estudiante=' + estudiante);
    }
    showVerificar(item) {
        return axios.post('api/Estudiante/showVerificar' , item);
    }
    showVerificarActivo(item) {
        return axios.post('api/Estudiante/showVerificarActivo' , item);
    }

    bucarEstudiante(estudiante) {
        return axios.get('api/Estudiante/buscarsaga?Estudiante=' + estudiante);
    }

    bucarDocente(doc) {
        return axios.get('api/Estudiante/buscarsagadocente?Doc=' + doc);
    }

    indexEstudiante() {
        return axios.defaults.baseURL + 'api/Estudiante/index';
    }

    listEstudiante() {
        return axios.get('api/Estudiante/list');
    }

    storeEstudiante(item) {
        return axios.post('api/Estudiante/store', item);
    }

    storeTotalEstudiante(item) {
        return axios.post('api/Estudiante/storeEstDocts', item);
    }


    destroyEstudiante(item) {
        return axios.post('api/Estudiante/destroy', item);
    }
    updateEstudiante(item) {
        return axios.post('api/Estudiante/update', item);
    }
    listarAlumnos() {
        return axios.get('api/Estudiante/listarAlumnos');
    }


    
    /*showDocente(id) {
        return axios.get('api/Docente/show?id=' + id);
    }

    indexDocente() {
        return axios.defaults.baseURL + 'api/Docente/index';
    }

    listDocente() {
        return axios.get('api/Docente/list');
    }

    storeDocente(item) {
        return axios.post('api/Docente/store', item);
    }

    destroyDocente(item) {
        return axios.post('api/Docente/destroy', item);
    }



    

    showTutor(id) {
        return axios.get('api/Tutor/show?id=' + id);
    }

    indexTutor() {
        return axios.defaults.baseURL + 'api/Tutor/index';
    }

    listTutor() {
        return axios.get('api/Tutor/list');
    }

    storeTutor(item) {
        return axios.post('api/Tutor/store', item);
    }

    destroyTutor(item) {
        return axios.post('api/Tutor/destroy', item);
    }




    showRevisor(id) {
        return axios.get('api/Revisor/show?id=' + id);
    }

    indexRevisor() {
        return axios.defaults.baseURL + 'api/Revisor/index';
    }

    listRevisor() {
        return axios.get('api/Revisor/list');
    }

    storeRevisor(item) {
        return axios.post('api/Revisor/store', item);
    }

    destroyRevisor(item) {
        return axios.post('api/Revisor/destroy', item);
    }*/


    showTipoMiembro(id) {
        return axios.get('api/TipoMiembro/show?id=' + id);
    }

    indexTipoMiembro() {
        return axios.defaults.baseURL + 'api/TipoMiembro/index';
    }

    listTipoMiembro() {
        return axios.get('api/TipoMiembro/list');
    }

    storeTipoMiembro(item) {
        return axios.post('api/TipoMiembro/store', item);
    }

    destroyTipoMiembro(item) {
        return axios.post('api/TipoMiembro/destroy', item);
    }









    showTemario(id) {
        return axios.get('api/Temario/show?id=' + id);
    }

    indexTemario() {
        return axios.defaults.baseURL + 'api/Temario/index';
    }

    listTemario() {
        return axios.get('api/Temario/list');
    }

    storeTemario(item) {
        return axios.post('api/Temario/store', item);
    }

    destroyTemario(item) {
        return axios.post('api/Temario/destroy', item);
    }






    showMiembrosTribunal(id) {
        return axios.get('api/MiembrosTribunal/show?id=' + id);
    }

    indexMiembrosTribunal() {
        return axios.defaults.baseURL + 'api/MiembrosTribunal/index';
    }

    listMiembrosTribunal() {
        return axios.get('api/MiembrosTribunal/list');
    }

    storeMiembrosTribunal(item) {
        return axios.post('api/MiembrosTribunal/store', item);
    }

    destroyMiembrosTribunal(item) {
        return axios.post('api/MiembrosTribunal/destroy', item);
    }


    showProyecto(id) {
        return axios.get('api/Proyecto/show?id=' + id);
    }

    indexProyecto() {
        return axios.defaults.baseURL + 'api/Proyecto/index';
    }

    listProyecto() {
        return axios.get('api/Proyecto/list');
    }

    storeProyecto(item) {
        return axios.post('api/Proyecto/store', item);
    }

    destroyProyecto(item) {
        return axios.post('api/Proyecto/destroy', item);
    }

    updateCorreosd(item) {
        return axios.post('api/Proyecto/updateCorreosd', item);
    }

    updateCorreos(item) {
        return axios.post('api/Proyecto/updateCorreos', item);
    }

    updateCorreosr(item) {
        return axios.post('api/Proyecto/updateCorreosr', item);
    }


    updateCorreosr2(item) {
        return axios.post('api/Proyecto/updateCorreosr2', item);
    }


    updateEtapa(item) {
        return axios.post('api/Proyecto/updateEtapa', item);
    }
    printProyecto(id){
            return axios.get('api/Proyecto/printProyecto?id=' + id)
    }

    printProyectoT(id){
        return axios.get('api/Proyecto/printProyectoT?id=' + id)
}
    printProyectoLic(id){
        return axios.get('api/Proyecto/printProyectoLic?id=' + id)
    }
    printProyectoLic1(id){
        return axios.get('api/Proyecto/printProyectoLic1?id=' + id)
    }
    printProyectoLic1T(id){
        return axios.get('api/Proyecto/printProyectoLic1T?id=' + id)
    }
    printNota(id){
        return axios.get('api/Proyecto/printNota?id=' + id)
    }
    printNotaLic(id){
        return axios.get('api/Proyecto/printNotaLic?id=' + id)
    }
    printNotaLic1(id){
        return axios.get('api/Proyecto/printNotaLic1?id=' + id)
    }
    printAval(item){
        return axios.post('api/Proyecto/printAval', item)
    }
    printInformer(item){
        return axios.post('api/Proyecto/printInformer', item)
    }
    printInformerMT(item){
        return axios.post('api/Proyecto/printInformerMT', item)
    }
    printInformerMP(item){
        return axios.post('api/Proyecto/printInformerMP', item)
    }
    printInformerB(item){
        return axios.post('api/Proyecto/printInformerB', item)
    }
    printInformerBF(item){
        return axios.post('api/Proyecto/printInformerBF', item)
    }
    printTemario(item){
        return axios.post('api/Proyecto/printTemario', item)
    }
    printCarta(item){
        return axios.post('api/Proyecto/printCarta', item)
    }

    printMemoDefensa(item){
        return axios.post('api/Proyecto/printMemoDefensa', item)
    }

    printEmpastado(item){
        return axios.post('api/Proyecto/printEmpastado', item)
    }
    printEmpastadoT(item){
        return axios.post('api/Proyecto/printEmpastadoT', item)
    }
    printMemoTutor(item){
        return axios.post('api/Proyecto/printMemoTutor', item)
    }




    showDefensas(id) {
        return axios.get('api/Defensas/show?id=' + id);
    }

    indexDefensas() {
        return axios.defaults.baseURL + 'api/Defensas/index';
    }

    listDefensas() {
        return axios.get('api/Defensas/list');
    }

    storeDefensas(item) {
        return axios.post('api/Defensas/store', item);
    }

    destroyDefensas(item) {
        return axios.post('api/Defensas/destroy', item);
    }
    indexEstudianteDefensa() {
        return axios.defaults.baseURL + 'api/Defensas/indexEstudianteDefensa';
    }





    showTipoInforme(id) {
        return axios.get('api/TipoInforme/show?id=' + id);
    }

    indexTipoInforme() {
        return axios.defaults.baseURL + 'api/TipoInforme/index';
    }

    listTipoInforme() {
        return axios.get('api/TipoInforme/list');
    }

    storeTipoInforme(item) {
        return axios.post('api/TipoInforme/store', item);
    }

    destroyTipoInforme(item) {
        return axios.post('api/TipoInforme/destroy', item);
    }





    showDocumentacion(id) {
        return axios.get('api/Documentacion/show?id=' + id);
    }

    indexDocumentacion() {
        return axios.defaults.baseURL + 'api/Documentacion/index';
    }

    listDocumentacion() {
        return axios.get('api/Documentacion/list');
    }

    storeDocumentacion(item) {
        return axios.post('api/Documentacion/store', item);
    }

    destroyDocumentacion(item) {
        return axios.post('api/Documentacion/destroy', item);
    }
    update(item) {
        return axios.post('api/Documentacion/update', item);
    }



    showCarta(id) {
        return axios.get('api/Carta/show?id=' + id);
    }

    indexCarta() {
        return axios.defaults.baseURL + 'api/Carta/index';
    }

    listCarta() {
        return axios.get('api/Carta/list');
    }

    storeCarta(item) {
        return axios.post('api/Carta/store', item);
    }

    destroyCarta(item) {
        return axios.post('api/Carta/destroy', item);
    }
    updateCarta(item) {
        return axios.post('api/Carta/update', item);
    }
    printCartaT(item){
        return axios.post('api/Carta/printCartaT', item)
    }
    printCartaAT(item){
        return axios.post('api/Carta/printCartaAT', item)
    }






    
    showConsultaAval(id) {
        return axios.get('api/ConsultaAval/show?id=' + id);
    }

    indexConsultaAval() {
        return axios.defaults.baseURL + 'api/ConsultaAval/index';
    }

    listConsultaAval() {
        return axios.get('api/ConsultaAval/list');
    }

    storeConsultaAval(item) {
        return axios.post('api/ConsultaAval/store', item);
    }

    destroyConsultaAval(item) {
        return axios.post('api/ConsultaAval/destroy', item);
    }

    storeLogin(item) {
        return axios.post('api/storeLogin', item);
    }
   





}