<template>
    <div id="app">
        <v-app id="inspire">

            <menu-externo
                v-if="
                    paginasSimples.includes(this.$router.currentRoute.name) &&
                    this.$router.currentRoute.name !== 'Login' 
                    //&&
                    //this.$router.currentRoute.name !== 'Main'
                "
            />

            <v-app-bar
                app
                color="#003f8a"
                dark
                flat
                v-if="this.$router.currentRoute.name !== 'Login' && this.$router.currentRoute.name !== 'Main' &&
                !paginasSimples.includes(this.$router.currentRoute.name)"
            >
                <v-app-bar-nav-icon
                    @click.stop="drawer = !drawer"
                    v-if="!$vuetify.breakpoint.mdAndUp"
                >
                </v-app-bar-nav-icon>
                <v-spacer></v-spacer>

                <v-btn icon>
                    <v-icon @click.prevent="cerrarSesion">mdi-logout</v-icon>
                </v-btn>
            </v-app-bar>

            <v-navigation-drawer
                color="white"
                v-model="drawer"
                :permanent="$vuetify.breakpoint.mdAndUp"
                app
                flat
                v-if="this.$router.currentRoute.name !== 'Login' && this.$router.currentRoute.name !== 'Main' &&
                !paginasSimples.includes(this.$router.currentRoute.name)"
            >
                <template v-slot:prepend>
                  <!--<div class="text-center">
                                <img
                                    src="/assets//images/emi_logo.png"
                                    width="180"
                                    height="60"
                                    alt="100"
                                    />
                            </div>-->
                    <div class="user-details">
                            
                            <div class="text-center rounded-circle">                                 
                             <img :src="auth.URLFoto" class="rounded-circle"/>
                            </div>
                            <div class="user-info">
                                <div class="dropdown">
                                </div>

                                <p class="text-muted m-0">{{auth.Usuario}}</p>
                                <v-list-item-subtitle>Sesion Iniciada Bienvenido</v-list-item-subtitle>
                                <v-list-item-subtitle>
                                      <p v-if="auth.Rol == 1" style="text-align: center">Administrador</p>
                                    <p v-if="auth.Rol == 2" style="text-align: center">Docente de Trabajo de Grado</p>
                                   <!--  <p v-if="auth.Rol == 3" style="text-align: center">Tutor</p>
                                    <p v-if="auth.Rol == 4" style="text-align: center">Revisor</p> -->
                                    <p v-if="auth.Rol == 5" style="text-align: center">Estudiante</p>
                                     <p v-if="auth.Rol == 6" style="text-align: center">Invitado</p>
                                    <!--  <p v-if="auth.Rol == 7" style="text-align: center">Segundo Revisor</p> -->
                                     <p v-if="auth.Rol == 8" style="text-align: center">Jefe de Carrera</p>
                                     <p v-if="auth.Rol == 10" style="text-align: center">Docente a Dedicación Exclusiva</p>
                                     <p v-if="auth.Rol == 9" style="text-align: center">Secretaria</p>
                                    </v-list-item-subtitle>

                              
                            
                            </div>
                    </div>
                </template>
                
                <v-divider></v-divider>
                <MenuVuetify />
            </v-navigation-drawer>
            <!-- Sizes your content based upon application components -->
            <v-main>
                <!-- If using vue-router -->

                <router-view></router-view>

                <footer-externo
                    v-if="
                        paginasSimples.includes(
                            this.$router.currentRoute.name
                        ) && this.$router.currentRoute.name !== 'Login'
                    "
                />

            </v-main>
        </v-app>
    </div>
</template>

<script>
import MenuVuetify from "./partials/MenuVuetify.vue";
import MenuExterno from "@/components/MenuExterno";
import FooterExterno from "@/components/FooterExterno";



export default {
    name: "app",
    components: {
        MenuVuetify,
        MenuExterno,
        FooterExterno
    },
    data: () => ({
        drawer: false,
        auth: {},
        paginasSimples: [
            "Main",
            "Login"
        ],
    }),
    mounted() {
        this.auth = JSON.parse(localStorage.getItem("usuario"));
    },
    methods: {
        cerrarSesion() {
            localStorage.clear();
            this.$router.push("/Login");
            if (this.$msal.isAuthenticated()) {
                this.$msal.signOut();
            }
            this.$router.go();
        },
    },
};
</script>


<style lang="scss">
@import "./assets/css/app.css";
@import "./assets/scss/app.scss";

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../node_modules/@syncfusion/ej2-vue-pdfviewer/styles/material.css";
/* table.dataTable td.text-center  {
    text-align: justify;
}
 */
</style>