import MainService from "@/services/MainService.js";

export default {
    name: "TipoReporteVerPage",
    data() {
        let ss = new MainService();
        return {
            msg: "TipoReporteVerPage",
            ss: ss,
            tipoReportes: {},
            selectedHitEst : 0,
            selectedHitUs : 0,
            reporte: {},
            usuarios: {},
            unidadAcademicas: {},
            gestions: {},	
            especialidades: {},
            enable: {
                UnidadAcademica:false,
                Usuario:false,
                Desde:false,
                Hasta:false,
              
               


            },
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            errorBag: {},
            auth : null,
        };
    },
    methods: {
        verFormato() {
            this.getTipoReportes();
        },

        showFormulario (id) {

            switch (id) { 
                case 1: 
                    this.reporte={};
                    this.reporte.Numero=1;
                    this.reporte.Archivo="EstudianteTG.jasper";
                    this.$refs['frm-formulario_1'].show(); break;

                    case 2: 
                    this.reporte={};
                    this.reporte.Numero=2;
                    this.reporte.Archivo="Semestre2024.jasper";
                    this.$refs['frm-formulario_2'].show(); break;

                    case 3: 
                    this.reporte={};
                    this.reporte.Numero=3;
                    this.reporte.Archivo="DefensaTG.jasper";
                    this.$refs['frm-formulario_3'].show(); break;


                    case 4: 
                    this.reporte={};
                    this.reporte.Numero=4;
                    this.reporte.Archivo="jorge.jasper";
                    this.$refs['frm-formulario_4'].show(); break;

                    case 5: 
                    this.reporte={};
                    this.reporte.Numero=5;
                    this.reporte.Archivo="HorarioDefensasTG.jasper";
                    this.$refs['frm-formulario_5'].show(); break;

                    case 6: 
                    this.reporte={};
                    this.reporte.Numero=6;
                    this.reporte.Archivo="ProyectosAreaI.jasper";
                    this.$refs['frm-formulario_6'].show(); break;


              
                   
              
            }     
        },
        verParametros() {
            //console.log(event);
            //console.log(this.reporte);
            this.ss.showTipoReporte(this.reporte.TipoReporte).then(
                (result) => {
                    //console.log(result);

                    var parametros = result.data.data.Parametros;
                    console.log(parametros);
                    if (parametros.includes(':idUnidadAcademica'))
                        this.enable.UnidadAcademica = true;
                    else
                        this.enable.UnidadAcademica = false;

                  
                 if (parametros.includes(':idEstudiante10'))
                        this.enable.EstudianteAvances = true;
                    else
                        this.enable.EstudianteAvances = false;  


                        
                    if (parametros.includes(':AreaInvestigacion,:Gestion'))
                    
                        this.enable.elab = true;
                    else
                        this.enable.elab = false;   



                    if (parametros.includes(':idGestion,:idEspecialidad'))
                    
                        this.enable.TGGENERAL = true;
                    else
                        this.enable.TGGENERAL = false;    


                    if (parametros.includes(':idEstudianteDEF,:idFase'))
                    
                        this.enable.DEF = true;
                    else
                        this.enable.DEF = false;  

                     
                        
                    if (parametros.includes(':idEspecialidad2,:idGestion2'))
                    
                        this.enable.ETR = true;
                    else
                        this.enable.ETR = false;  

                    if (parametros.includes(':idEspecialidad2,:idGestionRol,:idEtapa'))
                    
                        this.enable.ROL = true;
                    else
                        this.enable.ROL = false; 
                     

                    
                    
                        if (parametros.includes(':idUsuario'))
                        this.enable.Usuario = true;
                    else
                        this.enable.Usuario = false;


                    if (parametros.includes(':desde'))
                        this.enable.Desde = true;
                    else
                        this.enable.Desde = true;

                    if (parametros.includes(':hasta'))
                        this.enable.Hasta = true;
                    else
                        this.enable.Hasta = false;
                }
            );
        },
        setReset() {
            this.reporte = {};
        },
        getTipoReportes() {
            this.ss.listTipoReporte().then(
                result => this.tipoReportes = result.data.data
            ).catch(
                error => console.log(error)
            );
        },
        getUnidadAcademicas() {
            this.ss.listUnidadAcademica().then(
                result => this.unidadAcademicas = result.data.data
            ).catch(
                error => console.log(error)
            );
        },
        getEstudiante() {
            this.ss.listEstudiante().then(
                (result) => {
                    let response = result.data;
                    this.Estudiantes= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getFase() {
            this.ss.listFase().then(
                (result) => {
                    let response = result.data;
                    this.Fases= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getGestion() {
            this.ss.listGestion().then(
                (result) => {
                    let response = result.data;
                    this.Gestions= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEspecialidad() {
            this.ss.listEspecialidad().then(
                (result) => {
                    let response = result.data;
                    this.Especialidades= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getAreaInvestigacion() {
            this.ss.listAreaInvestigacion().then(
                (result) => {
                    let response = result.data;
                    this.AreaInvestigaciones= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getUsuario() {

            this.ss.listUsuario().then(
                result => {
                    let response = result.data;
                    this.usuarios = response.data;
                    //toastr.success('Usuarios Cargados', 'Correcto!');
                })
                .catch(error => {
                    console.log(error);
                    //toastr.error('Error de Carga', 'Error!');

                });
        },

        printRevisiones() {
           
            this.ss.printRevisiones( this.reporte)
                .then(result => {
                    let response = result.data;
                    var urlFile = response.url; // Cambia 'urlFile' para acceder directamente a la URL del PDF
                    var dir = this.rutaApp + urlFile;
                    window.open(dir, '_blank'); // Abre la URL en una nueva pestaña o ventana
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        },

        printtggeneral() {
           
            this.ss.printtggeneral( this.reporte)
                .then(result => {
                    let response = result.data;
                    var urlFile = response.url; // Cambia 'urlFile' para acceder directamente a la URL del PDF
                    var dir = this.rutaApp + urlFile;
                    window.open(dir, '_blank'); // Abre la URL en una nueva pestaña o ventana
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        },

        printDefensa() {
           
            this.ss.printDefensa( this.reporte)
                .then(result => {
                    let response = result.data;
                    var urlFile = response.url; // Cambia 'urlFile' para acceder directamente a la URL del PDF
                    var dir = this.rutaApp + urlFile;
                    window.open(dir, '_blank'); // Abre la URL en una nueva pestaña o ventana
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        },

        printTR() {
           
            this.ss.printTR( this.reporte)
                .then(result => {
                    let response = result.data;
                    var urlFile = response.url; // Cambia 'urlFile' para acceder directamente a la URL del PDF
                    var dir = this.rutaApp + urlFile;
                    window.open(dir, '_blank'); // Abre la URL en una nueva pestaña o ventana
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        },

        printDR() {
           
            this.ss.printDR( this.reporte)
                .then(result => {
                    let response = result.data;
                    var urlFile = response.url; // Cambia 'urlFile' para acceder directamente a la URL del PDF
                    var dir = this.rutaApp + urlFile;
                    window.open(dir, '_blank'); // Abre la URL en una nueva pestaña o ventana
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        },

        printPE() {
           
            this.ss.printPE( this.reporte)
                .then(result => {
                    let response = result.data;
                    var urlFile = response.url; // Cambia 'urlFile' para acceder directamente a la URL del PDF
                    var dir = this.rutaApp + urlFile;
                    window.open(dir, '_blank'); // Abre la URL en una nueva pestaña o ventana
                })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                });
        },


        generaReporte() {
            this.isLoading = true;
            //console.log(this.reporte);

            this.ss.generateTipoReporte(this.reporte).then(
                result => {
                    console.log(result);
                    this.isLoading = false;
                    let response = result.data;
                    if (response.success) {
                        this.setReset();

                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        var a = document.createElement("a");
                        a.href = response.data.url;
                        a.target = '_blank';
                        a.click();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                    this.errorBag = {};
                })
                .catch(error => {
                    this.isLoading = false;
                    console.log(error);
                    this.$bvToast.toast(
                        'Error al generar el reporte',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        }
    },
    watch: {
        selectedHitEst(value) {
            this.reporte.Estudiante = value.Estudiante;
           
            //console.log(value);
        },
        selectedHitUs(value) {
            this.reporte.Usuario = value.Usuario;
           
            //console.log(value);
        },
    },
    mounted() {
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        if (!usuario) {
            this.$router.push('/Login');
        } else {

            this.getUnidadAcademicas();
            this.getEstudiante();
            this.getGestion();
            this.getEspecialidad();
            this.getAreaInvestigacion();
            this.getFase();
            this.getUsuario();
            this.getTipoReportes();
         
            this.auth = usuario;
        }
    }
};
