import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ProfilePage",
    data() {
        let ss = new MainService();
        return {
            msg: "ProfilePage",
            ss: ss,
            ajax: {
                "url": ss.indexUsuario(),
                headers: ss.getToken(),
            },
            auth:{},
            usuario: {},
            unidadAcademicas: [],
            rols: [],
            isLoading: false,
            isLoadingFile:false,
           
            errorBag: {},
            password: {}
        };
    },
    methods: {

        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            this.usuario.Foto = result.data.data;
                            this.saveUsuario();
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

      /*   loadFile2(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            this.usuario.Firma = result.data.data;
                            this.saveUsuario();
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        }, */

         
        loadFile2(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
              // Validación del tipo de archivo
              if (input.files[0].type !== 'image/png') {
                this.$bvToast.toast(
                  'La firma seleccionada no esta en formato png',
                  {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000
                  }
                );
                this.isLoadingFile = false;
                return;
              }
          
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]);
              var data = new FormData();
              data.append('File', input.files[0]);
              this.ss.uploadFile(data)
                .then(result => {
                  if (result.data.success) {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Correcto',
                        variant: 'info',
                        autoHideDelay: 5000
                      }
                    );
                    this.usuario.Firma = result.data.data;
                    this.saveUsuario();
                  } else {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 5000
                      }
                    )
                  }
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el archivo',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el archivo',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                });
            }
          },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getRol() {
            this.ss.listRol().then(
                (result) => {
                    let response = result.data;
                    this.rols = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        showUsuario() {
            this.ss.showUsuario(this.auth.id)
                .then(result => {
                    let response = result.data;
                    this.usuario = response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        editUsuario() {
            this.$refs['frm-usuario'].show();
        },
        cambiopassword() {
            this.$refs['view-password'].show();
        },
        changePassword() {
            this.password.Usuario = this.usuario.id;
            this.ss.changePassword(this.password)
                .then( result => {
                    let response = result.data;
                    if ( response.success ) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['view-password'].hide();
                        this.$refs['view-usuario'].show();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                /*.catch( error => {
                    console.log( error );
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })*/
        },
        saveUsuario() {
            this.ss.storeUsuario(this.usuario)
                .then(result => {
                    let response = result.data;
                    this.usuario = response.data;

                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                })
                .catch(error => {
                    console.log(error);
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops!',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                });
        },
        cancelUsuario() {
            this.$refs['frm-usuario'].hide();
        },
        cancelPassword() {
            this.$refs['view-password'].hide();
        },
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        this.auth = JSON.parse(localStorage.getItem('usuario'));
        console.log(this.auth);
        if (!this.auth) {
            this.$router.push('/Login');
        } else {
            this.showUsuario();
            this.getRol();
            this.getUnidadAcademica();
            
        }
    }
};
