import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
import NumberInputSpinner from 'vue-number-input-spinner'
window.$ = window.jQuery = require("jquery");

export default {
    name: "DefensasPage",
    data() {
        let ss = new MainService();
        return {
            msg: "DefensasPage",
            ss: ss,
            ajax: {
                "url": ss.indexDefensas(),
                headers: ss.getToken(),
            },
            ajaxDEF: {
                "url": ss.indexEstudianteDefensa(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false , className: 'text-justify'},
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false , className: 'text-justify'},
                { data: 'Fase', name: 'fa.Fase', title: 'Fase', className: 'text-justify' },
                { data: 'Proyecto', name: 'def.Proyecto', title: 'Proyecto', className: 'text-justify' },
                { data: 'Estudiante', name: 'e.Estudiante', title: 'Estudiante' , className: 'text-justify'},
                {data: 'NotaReferencial', name: 'def.NotaReferencial', title: 'Nota Referencial', className: 'text-center' },
                {data: 'MiembroTribunal', name: 'def.MiembroTribunal', title: 'MiembroTribunal' , className: 'text-justify'},
                {data: 'Observacion', name: 'def.Observacion', title: 'Observación', className: 'text-justify'},
                {data: 'CI', name: 'def.CI', orderable: false, searchable: false, visible: false, className: 'text-justify'},
                
              
              
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false, className: 'text-center'
                },
            ],
            columns2: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false },
                { data: 'Fase', name: 'fa.Fase', title: 'Fase' },
                { data: 'TituloProyecto', name: 'est.TituloProyecto', title: 'Proyecto' },
                { data: 'Estudiante', name: 'e.Estudiante', title: 'Estudiante' },
                 {data: 'CI', name: 'def.CI', orderable: false, searchable: false, visible: false},
                
                {data: 'MiembroTribunal', name: 'def.MiembroTribunal', title: 'MiembroTribunal' },
                {data: 'Observacion', name: 'def.Observacion', title: 'Observacion' },
                
              
              
            ],
            
           
          
            Fases: [],
            Proyectos: [],
            Estudiantes: [],
            
            
            selectedHitEst: 0,
            auth : null,
            
            value: 1,
            defensas: {},
            isLoading: false,
            errorBag: {}
        };
        
    },
    methods: {
        aplicarFiltros(){

            let estu = this.defensas     
            let fase = this.defensas.Fase
                   
            this.ajax.data = function(d){
                d.Fase = fase
                d.Estudiante = estu
            }
            //console.log("prueba");
            this.$refs['datatable-defensas'].reloadAjax(this.ajax);
        },
        getFase() {
            this.ss.listFase().then(
                (result) => {
                    let response = result.data;
                    this.Fases= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getProyecto() {
            this.ss.listProyecto().then(
                (result) => {
                    let response = result.data;
                    this.Proyectos= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstudiante() {
            this.ss.listEstudiante().then(
                (result) => {
                    let response = result.data;
                    this.Estudiantes= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
       
       
        
        
        newDefensas() {
            this.defensas = {};
            this.$refs['frm-defensas'].show();
        },
        showDefensas(id) {
            this.isLoading=true;
            this.ss.showDefensas(id).then(
                (result) => {
                    let response = result.data;
                    this.defensas = response.data;
                    this.$refs['view-defensas'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editDefensas() {
            this.$refs['frm-defensas'].show();
            this.$refs['view-defensas'].hide();
        },
        cancelDefensas() {
            if (this.defensas.id) {
                this.$refs['view-defensas'].show();
            }
            this.$refs['frm-defensas'].hide();
        },
        saveDefensas() {
            if(!this.defensas.Fase){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el la fase de la Defensa",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

            if(!this.defensas.Observacion){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el la observación de la defensa",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }
            this.ss.storeDefensas(this.defensas).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-defensas'].hide();
                    this.$refs['datatable-defensas'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteDefensas() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                buttons: true,
                dangerMode: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.ss.destroyDefensas(this.defensas)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-defensas'].hide();
                                this.$refs['datatable-defensas'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                       
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Defensas').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showDefensas(data);
            });
           
        }
    },
    components: {
        dataTable,
        Loader,
        NumberInputSpinner,
        
    },
    watch: {
      
        selectedHitEst(value) {
            this.defensas.Estudiante = value.Estudiante;
            this.defensas.Proyecto = value.TituloProyecto;
            this.defensas.CI = value.CI;
            this.defensas.UnidadAcademica = value.UnidadAcademica;
            //console.log(value);
        },
      
    },
    mounted() {
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        if (!usuario) {
            this.$router.push('/Login');
        }else {
            this.getFase();
            this.getProyecto();
            this.getEstudiante();
            this.auth = usuario;
        }
    }
};
