var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('v-app',{attrs:{"id":"inspire"}},[(
                _vm.paginasSimples.includes(this.$router.currentRoute.name) &&
                this.$router.currentRoute.name !== 'Login' 
                //&&
                //this.$router.currentRoute.name !== 'Main'
            )?_c('menu-externo'):_vm._e(),(this.$router.currentRoute.name !== 'Login' && this.$router.currentRoute.name !== 'Main' &&
            !_vm.paginasSimples.includes(this.$router.currentRoute.name))?_c('v-app-bar',{attrs:{"app":"","color":"#003f8a","dark":"","flat":""}},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){$event.preventDefault();return _vm.cerrarSesion.apply(null, arguments)}}},[_vm._v("mdi-logout")])],1)],1):_vm._e(),(this.$router.currentRoute.name !== 'Login' && this.$router.currentRoute.name !== 'Main' &&
            !_vm.paginasSimples.includes(this.$router.currentRoute.name))?_c('v-navigation-drawer',{attrs:{"color":"white","permanent":_vm.$vuetify.breakpoint.mdAndUp,"app":"","flat":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('div',{staticClass:"user-details"},[_c('div',{staticClass:"text-center rounded-circle"},[_c('img',{staticClass:"rounded-circle",attrs:{"src":_vm.auth.URLFoto}})]),_c('div',{staticClass:"user-info"},[_c('div',{staticClass:"dropdown"}),_c('p',{staticClass:"text-muted m-0"},[_vm._v(_vm._s(_vm.auth.Usuario))]),_c('v-list-item-subtitle',[_vm._v("Sesion Iniciada Bienvenido")]),_c('v-list-item-subtitle',[(_vm.auth.Rol == 1)?_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("Administrador")]):_vm._e(),(_vm.auth.Rol == 2)?_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("Docente de Trabajo de Grado")]):_vm._e(),(_vm.auth.Rol == 5)?_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("Estudiante")]):_vm._e(),(_vm.auth.Rol == 6)?_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("Invitado")]):_vm._e(),(_vm.auth.Rol == 8)?_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("Jefe de Carrera")]):_vm._e(),(_vm.auth.Rol == 10)?_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("Docente a Dedicación Exclusiva")]):_vm._e(),(_vm.auth.Rol == 9)?_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("Secretaria")]):_vm._e()])],1)])]},proxy:true}],null,false,2352420233),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-divider'),_c('MenuVuetify')],1):_vm._e(),_c('v-main',[_c('router-view'),(
                    _vm.paginasSimples.includes(
                        this.$router.currentRoute.name
                    ) && this.$router.currentRoute.name !== 'Login'
                )?_c('footer-externo'):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }