import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ConsultaAvalPage",
    data() {

        let ss = new MainService();
        return {
          
            date: new Date().toISOString().substr(0, 10),
            picker: new Date().toISOString().substr(0, 10),

            msg: "ConsultaAvalPage",
            ss: ss,
            ajax: {
                "url": ss.indexConsultaAval(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false},
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false , className: 'text-center'},
                { data: 'Estudiante', name: 'e.Estudiante', title: 'Estudiante', width: '300px', className: 'text-center' },
                { data: 'TipoInforme', name: 'ti.TipoInforme', title: 'Informe', className: 'text-center'},
                { data: 'Fase', name: 'f.Fase', title: 'Fase', width: '300px', className: 'text-center'},
                {data: 'Rol', name: 'p.Rol', title: '', className: 'text-center'},
                //{ data: 'Usuario', name: 'p.Usuario', title: 'Responsable' },
                
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false, className: 'text-center'
                },
            ],
           
            TipoInformes: [],
            Fases: [],
           
            consultaaval: {},
            Estudiantes: [],
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,

            ex7: "0",
            auth : null,
            isLoading: false,
            isLoadingFile:false,
            errorBag: {}
        };
    },
    methods: {
      
        
         getFase() {
            this.ss.listFase().then(
                (result) => {
                    let response = result.data;
                    this.Fases= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstudiante() {
            this.ss.listEstudiante().then(
                (result) => {
                    let response = result.data;
                    this.Estudiantes= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getTipoInforme() {
            this.ss.listTipoInforme().then(
                (result) => {
                    let response = result.data;
                    this.TipoInformes= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        
        showConsultaAval(id) {
            this.isLoading=true;
            this.ss.showConsultaAval(id).then(
                (result) => {
                    let response = result.data;
                    this.consultaaval = response.data;
                    this.$refs['view-consultaaval'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        

       
       
      

       
        
        
        
        draw() {
            window.$('.btn-datatable-ConsultaAval').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showConsultaAval(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
   
    mounted() {
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        console.log(usuario);
        if (!usuario) {
          this.$router.push('/Login');
        }else{
           
            this.getFase();
            this.auth = usuario;
            this.getTipoInforme();
            this.getEstudiante();
            
        }
    }
};
