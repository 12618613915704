
import Select2 from 'v-select2-component';

import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";


window.$ = window.jQuery = require("jquery");

import $ from "jquery";
$(document).ready(function () {
    $('#Estudiante').select2();
  });


export default {
    
    name: "EstudiantePage",
    components: {
        dataTable,
        Loader,
        Select2
        
    },
    data() {
        let ss = new MainService();
        return {

            msg: "EstudiantePage",
            ss: ss,
            ajax: {
                "url": ss.indexEstudiante(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false , className: 'text-center'},
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false, className: 'text-center' },
                { data: 'Estudiante', name: 'e.Estudiante', title: 'Estudiante' , className: 'text-center'},
                {data: 'Semestre', name: 's.Semestre', title: 'Semestre' , className: 'text-center'},
                {data: 'CodigoSaga', name: 'e.CodigoSaga', title: 'CodigoSaga' , className: 'text-center'},
                {data: 'TituloProyecto', name: 'e.TituloProyecto', title: 'TituloProyecto' , className: 'text-justify'},
                {data: 'DocenteTrabajoDeGrado', name: 'e.DocenteTrabajoDeGrado', title: 'Docente' , className: 'text-center'},
                {data: 'Tutor', name: 'e.Tutor', title: 'Tutor' , className: 'text-center'},
                {data: 'Revisor', name: 'e.Revisor', title: 'Revisor' , className: 'text-center'},
              /*   {data: 'Etapa', name: 'p.Etapa', title: 'Etapa' , className: 'text-center'}, */
             
                // {data: 'Revisor2', name: 'e.Revisor2', title: 'Revisor2' },


                { data: 'Revisor2', name: 'e.Revisor2', title: 'Revisor Dos', className: 'text-center',render:function(data, type, row){
                    if (row.Revisor2 == null){
        
                        return  '<h5><span class="badge badge-primary">No Compete</span></h5>';
                        
                    }
                    else {
                         return  row.Revisor2;
                        
                    }
                } },

                {data: 'Gestion', name: 'g.Gestion', title: 'Gestión', className: 'text-center' },
                {data: 'Especialidad', name: 'e.Especialidad', title: 'Especialidad' , className: 'text-center'},

                { data: 'Activo', name: 'e.Activo', title: 'Activo', className: 'text-center', render:function(data, type, row){
                    if (row.Activo !== false){
        
                        return  '<div class="text-center text-success"><p class="text-center"> <b> <i class="fa fa-check"></i></b></p></div>';
                        
                    }
                    else {
                         return  '<div class="text-center text-danger"><p class="text-center"> <b> <i class="fa fa-times"></i> </b></p> </div>';
                        
                    }
                } },

                // {data: 'Activo', name: 'e.Activo', title: 'Activo' },
              
                
              
                /*{
                    data: 'member_group',
                    orderable: false,
                    title: 'Acciones',
                    width: '300px',
                    searchable: false
                },*/
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false, className: 'text-center'
                },
              
            ],
            Estudiantes: [],
            Estudiante: [],
            listaAlumnos: {},
            Usuarios: [],
            EUsuarios: [],
            DUsuarios: [],
            TUsuarios: [],
            lAlumnos: [],
            lDocentes: [],
            Especialidades: [],
            NivelAcademicos: [],
            Gestiones: [],
            Semestres: [],
            DocenteTrabajoDeGrado: [],
            Tutor: [],
            Revisor: [],
            rols:[],
           

            placeholderEst : '',
            selectedHitEst: 0,

            placeholderDoc : '',
            selectedHitDoc : 0,
            selectedHitDoc2 : 0,

            placeholderTutor : '',
            selectedHitTutor : 0,

            placeholderRevisor : '',
            selectedHitRevisor : 0,

            placeholderRevisor2 : '',
            selectedHitRevisor2 : 0,
            
            placeholderEspe : '',
            selectedHitEspe : null,

            auth : null,

         
            estudiante: {
                Estudiante:"",
                DocenteTrabajoDeGrado:"",
                Tutor:"",
                Revisor:"",
                Revisor2:"",
                Especialidad:"",
                TituloProyecto: ''
            },
            estudiantee2:{},

            docentes:[],
            Docente:{},
            estudianteSaga:{},
            docenteSaga:{},
            isLoading: false,
            botonSaga: false,
            botonSD: false,
            errorBag: {},
            idEspecialidad: '',
            myValue: '',
            myOptions: ['op1', 'op2', 'op3'], // or [{id: key, text: value}, {id: key, text: value}],
            objguardarTotalSaga:{},
            mostrarArchivo: false,
            showFileInput: false,
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            previousRevisor: null

            
        };
    },
    methods: {
            toggleFileInput() {
            this.showFileInput = !!this.estudiante.TituloProyecto;
          },
          toggleFileInputT() {
            this.showFileInputT = !!this.estudiante.Tutor;
          },

          toggleFileInputR() {
            // Se verifica si el campo this.estudiante.Revisor está vacío
            if (!this.estudiante.Revisor) {
              // Si el campo estaba lleno anteriormente, se establece showFileInputR en false
              if (this.showFileInputR) {
                this.showFileInputR = false;
              }
            } else {
              // Si el campo no estaba vacío anteriormente, se establece showFileInputR en true
              if (!this.showFileInputR) {
                this.showFileInputR = true;
              }
            }
          },
          
          
          
          


          onFileChange(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
              // Validación del tipo de archivo
              if (input.files[0].type !== 'application/pdf') {
                this.$bvToast.toast(
                  'El archivo seleccionado no es un Documento PDF',
                  {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000
                  }
                );
                this.isLoadingFile = false;
                return;
              }
          
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]);
              var data = new FormData();
              data.append('File', input.files[0]);
              this.ss.uploadFile(data)
                .then(result => {
                  if (result.data.success) {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Correcto',
                        variant: 'info',
                        autoHideDelay: 5000
                      }
                    );
                    this.estudiante.RespaldoTitulo = result.data.data;
                  } else {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 5000
                      }
                    )
                  }
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                });
            }
          },
          onFileChangeT(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
              // Validación del tipo de archivo
              if (input.files[0].type !== 'application/pdf') {
                this.$bvToast.toast(
                  'El archivo seleccionado no es un Documento PDF',
                  {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000
                  }
                );
                this.isLoadingFile = false;
                return;
              }
          
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]);
              var data = new FormData();
              data.append('File', input.files[0]);
              this.ss.uploadFile(data)
                .then(result => {
                  if (result.data.success) {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Correcto',
                        variant: 'info',
                        autoHideDelay: 5000
                      }
                    );
                    this.estudiante.RespaldoTutor = result.data.data;
                  } else {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 5000
                      }
                    )
                  }
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                });
            }
          },
          onFileChangeR(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
              // Validación del tipo de archivo
              if (input.files[0].type !== 'application/pdf') {
                this.$bvToast.toast(
                  'El archivo seleccionado no es un Documento PDF',
                  {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000
                  }
                );
                this.isLoadingFile = false;
                return;
              }
          
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]);
              var data = new FormData();
              data.append('File', input.files[0]);
              this.ss.uploadFile(data)
                .then(result => {
                  if (result.data.success) {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Correcto',
                        variant: 'info',
                        autoHideDelay: 5000
                      }
                    );
                    this.estudiante.RespaldoRevisor = result.data.data;
                  } else {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 5000
                      }
                    )
                  }
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                });
            }
          },
          verRespaldoTitulo() {
            
            // Concatenas la rutaApp con la ruta del documento
            const rutaCompleta = this.rutaApp + 'storage/documents/' + this.estudiante.RespaldoTitulo;
            // Abres una nueva ventana con la ruta del documento
            window.open(rutaCompleta, '_blank');
        },
        verRespaldoTutor() {
            
            // Concatenas la rutaApp con la ruta del documento
            const rutaCompleta = this.rutaApp + 'storage/documents/' + this.estudiante.RespaldoTutor;
            // Abres una nueva ventana con la ruta del documento
            window.open(rutaCompleta, '_blank');
        },
        verRespaldoRevisor() {
            
            // Concatenas la rutaApp con la ruta del documento
            const rutaCompleta = this.rutaApp + 'storage/documents/' + this.estudiante.RespaldoRevisor;
            // Abres una nueva ventana con la ruta del documento
            window.open(rutaCompleta, '_blank');
        },
          updateEstudiante() {


                            if(this.estudiante.idEtapa !== 1 || !this.estudiante.idEtapa){
                            if( this.showFileInput ){
                                
                                if( !this.estudiante.RespaldoTitulo){
                                    this.$swal({
                                        title: "Error al Guardar , datos incompletos",
                                        text: "Por favor debe de cargar el Respaldo de cambio de Titulo",
                                        icon: "warning",
                                        dangerMode: true,
                                    })
                                    return;
                                }
                            }

                            if( this.showFileInputT ){
                                
                                if( !this.estudiante.RespaldoTutor){
                                    this.$swal({
                                        title: "Error al Guardar , datos incompletos",
                                        text: "Por favor debe de cargar el Respaldo de cambio de Tutor",
                                        icon: "warning",
                                        dangerMode: true,
                                    })
                                    return;
                                }
                            }

                                /*    if( this.showFileInputR ){
                
                if( !this.estudiante.RespaldoRevisor){
                    this.$swal({
                        title: "Error al Guardar , datos incompletos",
                        text: "Por favor debe de cargar el Respaldo de cambio de Revisor",
                        icon: "warning",
                        dangerMode: true,
                    })
                    return;
                }
            } */
                        }
     
            this.ss.updateEstudiante(this.estudiante).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-edit'].hide();
                    this.$refs['datatable-estudiante'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        aplicarFiltros(){

            let gest = this.estudiante.Gestion     
            let espe = this.estudiante.Especialidad
            let sem = this.estudiante.Semestre
                   
            this.ajax.data = function(d){
                d.Especialidad = espe
                d.Gestion = gest
                d.Semestre = sem
            }
            //console.log("prueba");
            this.$refs['datatable-estudiante'].reloadAjax(this.ajax);
        },

        m() {
            const selectElement = document.querySelector('#NivelAcademico');
            const r2Element = document.getElementById('r2');
        
            selectElement.addEventListener('change', (event) => {
                const seleccionado = event.target.value;
                
                if (seleccionado === '1') {
                    r2Element.style.display = 'block';
                } 
            });
        
            const inputRevisor2 = document.querySelector('#r2 input[type="text"]');
            inputRevisor2.addEventListener('input', () => {
                r2Element.style.display = 'block';
            });
        },
           getUsuario() {
            this.ss.listUsuario().then(
                (result) => {
                    let response = result.data;
                    this.Usuarios= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstudiante() {
            this.ss.listUEstudiante().then(
                (result) => {
                    let response = result.data;
                    this.EUsuarios= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getDocente() {
            this.ss.listUDocente().then(
                (result) => {
                    let response = result.data;
                    this.DUsuarios= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getTRR() {
            this.ss.listUTRR().then(
                (result) => {
                    let response = result.data;
                    this.TUsuarios= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
       
        getEspecialidad() {
            this.ss.listEspecialidad().then(
                (result) => {
                    let response = result.data;
                    this.Especialidades = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

       

        listarAlumnos() {
            this.ss.listarAlumnos().then(
                (result) => {
                    let response = result.data;
                    this.lAlumnos= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

       
        getNivelAcademico() {
            this.ss.listNivelAcademico().then(
                (result) => {
                    let response = result.data;
                    this.NivelAcademicos = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getGestion() {
            this.ss.listGestion().then(
                (result) => {
                    let response = result.data;
                    this.Gestiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
    
        getSemestre() {
            this.ss.listSemestre().then(
                (result) => {
                    let response = result.data;
                    this.Semestres = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
       
        newEstudiante() {
            this.estudiante = {};
            this.Docente = {};
            this.botonSaga=false;
            this.botonSD=false;
            this.$refs['frm-estudiante'].show();
        },

        abrirmodalsaga() {
            this.estudiante = {};
            this.botonSaga=false;
            this.docentes = [];
            this.$refs['reg-saga'].show();
        },

        cerrarmodalsaga() {
            this.estudiante = {};
            this.$refs['reg-saga'].hide();
        },

        volverBuscar(){
            this.botonSaga=false;

        },


        bucarEstudiante() {

            if(!this.estudiante.Estudiante){
                this.$swal({
                    title: "Error al bucar",
                    text: "Por favor ingrese un numero de carnet",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }
            this.botonSaga=true;
            this.isLoading=true;
            this.ss.bucarEstudiante(this.estudiante.Estudiante).then(
                (result) => {
                    let response = result.data;
                    this.estudianteSaga = response.data;

                    this.estudiante.Nombres = this.estudianteSaga.Nombre;
                    this.estudiante.ApellidoPaterno = this.estudianteSaga.ApPaterno;
                    this.estudiante.ApellidoMaterno = this.estudianteSaga.ApMaterno;
                    this.estudiante.Usuario = this.estudianteSaga.Alumno;
                    this.estudiante.CodigoSaga = this.estudianteSaga.CodAlumno;
                    this.estudiante.CI = this.estudianteSaga.CI;
                    this.estudiante.email = this.estudianteSaga.EMail;
                    this.estudiante.CorreoEmi = this.estudianteSaga.email_emi;
                    this.estudiante.UnidadAcademica = this.estudianteSaga.idUnidadAcademica;

                    if(this.estudianteSaga.idUnidadAcademica == 1)
                    {
                        this.estudiante.UnidadAcademica = 2;
                        this.estudiante.NUnidadAcademica = 'La Paz';
                    }

                    if(this.estudianteSaga.idUnidadAcademica == 2)
                    {
                        this.estudiante.UnidadAcademica = 3;
                        this.estudiante.NUnidadAcademica = 'Santa Cruz';
                    }

                    if(this.estudianteSaga.idUnidadAcademica == 3)
                    {
                        this.estudiante.UnidadAcademica = 4;
                        this.estudiante.NUnidadAcademica = 'Cochabamba';
                    }

                    if(this.estudianteSaga.idUnidadAcademica == 4)
                    {
                        this.estudiante.UnidadAcademica = 5;
                        this.estudiante.NUnidadAcademica = 'Riberalta';
                    }

                    if(this.estudianteSaga.idUnidadAcademica == 5)
                    {
                        this.estudiante.UnidadAcademica = 6;
                        this.estudiante.NUnidadAcademica = 'Trópico';
                    }
                   /*  this.estudiante.UnidadAcademica = this.estudianteSaga.idUnidadAcademica;
                    this.estudiante.NUnidadAcademica = this.estudianteSaga.UnidadAcademica; 
 */

                    this.estudiante.Estudiante = this.estudianteSaga.Alumno;
                    this.estudiante.EmailEstudiante = this.estudianteSaga.email_emi;
                    this.estudiante.Especialidad = this.estudianteSaga.idEspecialidad;
                    this.estudiante.NEspecialidad = this.estudianteSaga.Especialidad;
                    this.estudiante.NivelAcademico = this.estudianteSaga.idNivelAcad;
                    this.estudiante.NNivelAcademico = this.estudianteSaga.NivelAcad; 
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
                this.botonSaga=false;
            });
        },


        getRol() {
            this.ss.listRol().then(
                (result) => {
                    let response = result.data;
                    this.rols = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        }, 
        
        bucarDocente() {
            this.botonSD=true;
            this.isLoading=true;
            this.ss.bucarDocente(this.Docente.Doc).then(
                (result) => {
                    let response = result.data;
                    this.docenteSaga = response.data;

                    this.Docente.Nombres = this.docenteSaga.Nombre;
                    this.Docente.ApellidoPaterno = this.docenteSaga.ApPaterno;
                    this.Docente.ApellidoMaterno = this.docenteSaga.ApMaterno;
                    this.Docente.Usuario = this.docenteSaga.Docente;
                    // this.Docente.CodigoSaga = this.estudianteSaga.CodAlumno;
                    this.Docente.CI = this.docenteSaga.CI;
                    this.Docente.email = this.docenteSaga.EMail;
                    this.Docente.CorreoEmi = this.docenteSaga.email_emi;

                    if(this.docenteSaga.idUnidadAcademica == 1)
                    {
                        this.Docente.UnidadAcademica = 2;
                        this.Docente.NUnidadAcademica = 'La Paz';
                    }

                    if(this.docenteSaga.idUnidadAcademica == 2)
                    {
                        this.Docente.UnidadAcademica = 3;
                        this.Docente.NUnidadAcademica = 'Santa Cruz';
                    }

                    if(this.docenteSaga.idUnidadAcademica == 3)
                    {
                        this.Docente.UnidadAcademica = 4;
                        this.Docente.NUnidadAcademica = 'Cochabamba';
                    }
                    
                    if(this.docenteSaga.idUnidadAcademica == 4)
                    {
                        this.Docente.UnidadAcademica = 5;
                        this.Docente.NUnidadAcademica = 'Riberalta';
                    }

                    if(this.docenteSaga.idUnidadAcademica == 5)
                    {
                        this.Docente.UnidadAcademica = 6;
                        this.Docente.NUnidadAcademica = 'Trópico';
                    }
                    /* this.Docente.UnidadAcademica = this.docenteSaga.idUnidadAcademica;
                    this.Docente.NUnidadAcademica = this.docenteSaga.UnidadAcademica; */

                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
                this.botonSD=false;

            });
        },

        buscardosDocente(){
            this.botonSD=false;
            this.Docente={};

        },

        anadirDocente(){
            if(!this.Docente.Rol){
                this.$swal({
                    title: "Error al añadir",
                    text: "Por favor seleccione un rol para el docente",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }


            let elrol = {};
            elrol = this.rols.filter(e => {
                return e.id == this.Docente.Rol;
            })[0];
            console.log("Esta es el rol", elrol);
            this.Docente.NRol = elrol.Rol;
           


            this.docentes.push(this.Docente);
            this.Docente={};
            this.botonSD=false;

            // console.log("array",this.docentes);
        },

        quitarDocente(Doc){
            this.docentes = this.docentes.filter(function(i) { return i !== Doc });
            // console.log( this.docentes );
        },
        handleCIKeyup(event) {
            let words = this.estudiante.Estudiante.split(' ');
            if (words.length > 2) {
                // Verifica si ya hay una coma presente
                if (!this.estudiante.Estudiante.includes(',')) {
                    this.estudiante.Estudiante = words.slice(0, 2).join(' ') + ', ' + words.slice(2).join(' ');
                } else {
                    this.estudiante.Estudiante = words.slice(0, 2).join(' ') + ' ' + words.slice(2).join(' ');
                }
            }
            if (event.keyCode === 13) {
                this.$refs.CIInput.blur(); // Oculta el teclado numérico en dispositivos móviles
                this.bucarEstudiante();
            }
        },
        
          handleCIKeyup2(event) {
            
            let words = this.Docente.Doc.split(' ');
            if (words.length > 2) {
                // Verifica si ya hay una coma presente
                if (!this.Docente.Doc.includes(',')) {
                    this.Docente.Doc = words.slice(0, 2).join(' ') + ', ' + words.slice(2).join(' ');
                } else {
                    this.Docente.Doc = words.slice(0, 2).join(' ') + ' ' + words.slice(2).join(' ');
                }
            }

            if (event.keyCode === 13) {
              this.$refs.CIInput2.blur(); // Oculta el teclado numérico en dispositivos móviles
              this.bucarDocente();
            }
          },

        guardarTotalEstudiante(){
        


            if(!this.estudiante.Semestre){
                this.$swal({
                    title: "Error al Guardar, datos incompletos",
                    text: "Por favor llene el semestre del estudiante ",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

            if(!this.estudiante.CI){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor llene el titulo del proyecto ",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

            if(!this.estudiante.Gestion){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor seleccione una Gestion ",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

            if(!this.estudiante.TituloProyecto){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el Titulo del Proyecto ",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }            

            if(this.docentes.length == 0){
                this.$swal({
                    title: "Error al Guardar, datos incompletos",
                    text: "Seleccione por lo menos un Docente",
                    icon: "warning",
                    dangerMode: true,
                })
                return;

                
            }

            if(this.docentes.length > 0){
                let vardoc = 0;
                let vartutor = 0;

                this.docentes.forEach(element => {
                    if(element.Rol == 2){
                        vardoc = vardoc +1;
                    }

                    if(element.Rol == 3){
                        vartutor = vartutor +1;
                    }
                });

                if(vardoc == 0 ){
                    this.$swal({
                        title: "Error al Guardar, datos incompletos",
                        text: "Seleccione el Docente de Trabajo de Grado ",
                        icon: "warning",
                        dangerMode: true,
                    })
                    return;
                }
                if(vartutor == 0 ){
                    this.$swal({
                        title: "Error al Guardar, datos incompletos",
                        text: "Seleccione el Docente Tutor ",
                        icon: "warning",
                        dangerMode: true,
                    })
                    return;
                }
            } 
            this.objguardarTotalSaga.ComEstudiante = this.estudiante;
            this.objguardarTotalSaga.ComDocentes = this.docentes;

            // console.log(this.objguardarTotalSaga);
            this.ss.storeTotalEstudiante(this.objguardarTotalSaga).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                    this.$refs['reg-saga'].hide();
                    this.$refs['datatable-estudiante'].reload();
                    this.isLoading=false;

                    
                })
                 .catch(() => {
                    
                    this.$bvToast.toast(
                        'Registro guardado satisfactoriamente',
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                        this.$refs['reg-saga'].hide();
                        this.$refs['datatable-estudiante'].reload();
                        //location.reload();
                       
                    
                });
        },

        showEstudiante(id) {
            this.isLoading=true;
            this.ss.showEstudiante(id).then(
                (result) => {
                    let response = result.data;
                    this.estudiante = response.data;
                    this.$refs['view-estudiante'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        showVerificar() {
            this.isLoading=true;
            this.ss.showVerificar(this.estudiante).then(
                (result) => {
                    let response = result.data;
                    if(response.data==null) {
                        this.guardarTotalEstudiante();
                    }else {
                        this.$swal({
                            title: "Este estudiantes ya esta registrado",
                            text: "Desea continuar con el registro? el anterior será inactivo",
                            icon: "warning",
                            showCloseButton: true,
                            showCancelButton: true,
                            cancelButtonColor: '#d33',
                            buttons: true,
                            dangerMode: true,
                        })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    this.showVerificarActivo();
                                    this.isLoading=false;
                                } else {
                                    //swal("Your imaginary file is safe!");
                                   
                                }})
                    }
               
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        showVerificarActivo() {
        
            this.ss.showVerificarActivo(this.estudiante).then(
                (result) => {
                    let response = result.data;

                    if(response.success == true){
                        this.guardarTotalEstudiante();
                        this.isLoading=false;
                    }
                    
                    
               
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editEstudiante() {
            this.$refs['frm-edit'].show();
            this.$refs['view-estudiante'].hide();
        },
        cancelEstudiante() {
            if (this.estudiante.id) {
                this.$refs['view-estudiante'].show();
            }
            
            this.$refs['frm-estudiante'].hide();
            this.$refs['view-estudiante'].hide();
            this.$refs['frm-edit'].hide();
        },
        saveEstudiante() {
            this.estudiante.Especialidad = this.estudiantee2.Especialidad
            console.log(this.estudiante);
            this.ss.storeEstudiante(this.estudiante).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-estudiante'].hide();
                    this.$refs['datatable-estudiante'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
      
        mostrarIdEspecialidad() {
            const especialidadSeleccionada = this.Especialidades.find(
              especialidad => especialidad.Especialidad === this.estudiantee2.Especialidad
            );
      
            this.estudiante.idEspecialidad = especialidadSeleccionada ? especialidadSeleccionada.id : '';
          }
        ,

        mostrarIdEspecialidad2() {
            const especialidadSeleccionada = this.Especialidades.find(
              especialidad => especialidad.Especialidad === this.estudiante.Especialidad
            );
      
            this.estudiante.idEspecialidad = especialidadSeleccionada ? especialidadSeleccionada.id : '';
          }
        ,
        deleteEstudiante() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                buttons: true,
                dangerMode: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.ss.destroyEstudiante(this.estudiante)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-estudiante'].hide();
                                this.$refs['datatable-estudiante'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                       
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Estudiante').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showEstudiante(data);
            });
        }
    },
   watch: {
    selectedHitEst(value) {
        this.estudiante.Estudiante = value.Usuario;
        //this.estudiante.Estudiante = value.Alumno;
        this.estudiante.EmailEstudiante = value.CorreoEmi;
        this.estudiante.UnidadAcademica = value.UnidadAcademica;
        this.estudiante.idUsuario = value.id;
        this.estudiante.Especialidad = value.Especialidad;
        this.estudiante.CodigoSaga = value.CodAlumno;
        //console.log(value);
    },
    selectedHitDoc(value) {
        this.estudiante.DocenteTrabajoDeGrado = value.Usuario;
        this.estudiante.EmailDocente = value.CorreoEmi;
        //console.log(value);
    },
    selectedHitTutor(value) {
        this.estudiante.Tutor = value.Usuario;
        this.estudiante.TutorAntiguo = value.Usuario;
        this.estudiante.EmailTutor = value.CorreoEmi;
        this.estudiante.EmailTutorAntiguo = value.CorreoEmi;
        //console.log(value);
    },
    selectedHitRevisor(value) {
        this.estudiante.Revisor = value.Usuario;
        this.estudiante.EmailRevisor = value.CorreoEmi;

        this.estudiante.RevisorAntiguo = value.Usuario;
        this.estudiante.EmailRevisorAntiguo = value.CorreoEmi;
        //console.log(value);
    },
  
    selectedHitRevisor2(value) {
        this.estudiante.Revisor2 = value.Usuario;
        this.estudiante.EmailRevisor2 = value.CorreoEmi;

        this.estudiante.Revisor2Antiguo = value.Usuario;
        this.estudiante.EmailRevisor2Antiguo = value.CorreoEmi;
        //console.log(value);
    },
    selectedHitEspe(value) {
        this.estudiante.Especialidad = value.Especialidad;
        //console.log(value);
    },
    
   },
    mounted() {
        
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        

        if (!usuario) {
            this.$router.push('/Login');
        }else {
            this.auth = usuario;
            this.getUsuario();
            this.getEstudiante();
            this.getDocente();
            this.getTRR();
            this.listarAlumnos();
            this.getEspecialidad();
            this.getNivelAcademico();
            this.getSemestre();
            this.getGestion();
            this.getRol();
        
           
        }
    }
       
};
