import { render, staticRenderFns } from "./FuncionPage.vue?vue&type=template&id=1b11d47c"
import script from "./FuncionPage.js?vue&type=script&lang=js&external"
export * from "./FuncionPage.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports