import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";

window.$ = window.jQuery = require("jquery");

export default {
    name: "UsuarioPage",
    data() {
        let ss = new MainService();
        
        return {
            msg: "UsuarioPage",
            ss: ss,
            base64textString: '',
            ajax: {
                "url": ss.indexUsuario(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false },
                { data: 'URLFoto', searchable: false ,name: 'p.Foto', title: 'Foto', render: function(data,type ,row)
                { return '  <img src="'+row.URLFoto+'" style="width: 100px; height: auto;" />'}, width: '200px'},
                { data: 'Usuario', name: 'p.Usuario', title: 'Usuario', width: '600px'},
                { data: 'Rol', name: 'p.Rol', title: 'Rol', width: '600px'},
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            unidadAcademicas: [],
            rols: [],
            usuarios: [],
            usuario: {},
            isLoading: false,
            isLoadingFile: false,
            errorBag: {},
            Especialidades: [],
            password: {},
            auth : null,
            showModal : false,
        };
    },
    methods: { 
        
        aplicarFiltros(){

            let r = this.usuario.Rol     
        
                   
            this.ajax.data = function(d){
                d.Rol = r
         
            }
         
            this.$refs['datatable-usuario'].reloadAjax(this.ajax);
        },
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.usuario.Foto = result.data.data;

                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        loadFile2(input ) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.usuario.Firma = result.data.data;

                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadAcademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getRol() {
            this.ss.listRol().then(
                (result) => {
                    let response = result.data;
                    this.rols = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newUsuario() {
            this.usuario = {};
            this.$refs['frm-usuario'].show();
        },
        showUsuario(id) {
            this.isLoading=true;
            this.ss.showUsuario(id).then(
                (result) => {
                    let response = result.data;
                    this.usuario = response.data;
                    this.$refs['view-usuario'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editUsuario() {
            this.$refs['frm-usuario'].show();
            this.$refs['view-usuario'].hide();
        },
        cancelUsuario() {
            if (this.usuario.id) {
                this.$refs['view-usuario'].show();
            }
            this.$refs['frm-usuario'].hide();
        },
        saveUsuario() {
            //this.usuario.email = this.usuario.CorreoEmi;

            if(!this.usuario.CorreoEmi) {
                this.usuario.CorreoEmi = this.usuario.email;
            }

            if(!this.usuario.email) {
                this.usuario.email = this.usuario.CorreoEmi;
            } 
            this.ss.storeUsuario(this.usuario).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-usuario'].hide();
                    this.$refs['datatable-usuario'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteUsuario() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                buttons: true,
                dangerMode: true,
                
            })
            .then((result) => {
                if (result.isConfirmed) {
                        this.ss.destroyUsuario(this.usuario)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-usuario'].hide();
                                this.$refs['datatable-usuario'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        cambiopassword() {
            this.$refs['view-usuario'].hide();
            this.$refs['view-password'].show();
        },
        cancelPassword() {
            this.$refs['view-password'].hide();
            this.$refs['view-usuario'].show();
        },
        changePassword() {
            this.password.Usuario = this.usuario.id;
            this.ss.changePassword(this.password)
                .then( result => {
                    let response = result.data;
                    if ( response.success ) {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['view-password'].hide();
                        this.$refs['view-usuario'].show();
                    } else {
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Oops',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch( error => {
                    console.log( error );
                    this.$bvToast.toast(
                        'Error al guardar el registro',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.errorBag = error.data.errors;
                })
        },
        getEspecialidad() {
            this.ss.listEspecialidad().then(
                (result) => {
                    let response = result.data;
                    this.Especialidades = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        mostrarIdEspecialidad() {
            const especialidadSeleccionada = this.Especialidades.find(
              especialidad => especialidad.Especialidad === this.usuario.Especialidad
            );
      
            this.usuario.idEspecialidad = especialidadSeleccionada ? especialidadSeleccionada.id : '';
          }
        ,
        mostrarIdEspecialidad2() {
            const especialidadSeleccionada = this.Especialidades.find(
              especialidad => especialidad.Especialidad === this.usuario.Especialidad2
            );
      
            this.usuario.idEspecialidad2 = especialidadSeleccionada ? especialidadSeleccionada.id : '';
          }
        ,

        mostrarIdEspecialidad3() {
            const especialidadSeleccionada = this.Especialidades.find(
              especialidad => especialidad.Especialidad === this.usuario.Especialidad3
            );
      
            this.usuario.idEspecialidad3 = especialidadSeleccionada ? especialidadSeleccionada.id : '';
          }
        ,

        mostrarIdEspecialidad4() {
            const especialidadSeleccionada = this.Especialidades.find(
              especialidad => especialidad.Especialidad === this.usuario.Especialidad4
            );
      
            this.usuario.idEspecialidad4 = especialidadSeleccionada ? especialidadSeleccionada.id : '';
          }
        ,
        mostrarIdEspecialidad5() {
            const especialidadSeleccionada = this.Especialidades.find(
              especialidad => especialidad.Especialidad === this.usuario.Especialidad5
            );
      
            this.usuario.idEspecialidad5 = especialidadSeleccionada ? especialidadSeleccionada.id : '';
          }
        ,
        draw() {
            window.$('.btn-datatable-Usuario').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showUsuario(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        this.auth = usuario;

        /* if(this.auth.UnidadAcademica == null || this.auth.ApellidoPaterno == null || this.auth.ApellidoMaterno == null || this.auth.Nombres == null || this.auth.email == null || this.auth.CorreoEmi == null || this.auth.CI == null){
            this.$swal.fire({
                title: '<strong>Complete sus datos Personales</strong>',
                icon: 'info',
            });
        } */

        this.ss.showUsuario(this.auth.id).then(
            (result) => {
                let response = result.data;
                this.usuario = response.data;
                //this.$refs['view-usuario'].show();

                if (
                    this.auth.UnidadAcademica == null ||
                    this.auth.ApellidoPaterno == null ||
                    this.auth.ApellidoMaterno == null ||
                    this.auth.Nombres == null ||
                    this.auth.email == null ||
                    this.auth.CorreoEmi == null ||
                    this.auth.CI == null
                  ) {

                    this.showModal = true;
                    this.$swal.fire({
                    title: '<strong>Complete sus datos Personales, Para poder usar correctamente el sistema</strong>',
                    icon: 'info',
                });
                  }
                
                this.isLoading=false;
            }
        ).catch(error => {
            console.log(error);
            this.isLoading=false;
        });

        if (!usuario) {
            this.$router.push('/Login');
        } else {
            this.getUnidadAcademica();
            this.getRol();
            this.auth = usuario;
            this.getEspecialidad();
        }
    }
};
