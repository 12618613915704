import Vue from 'vue'
import Router from 'vue-router'
import LoginPage from '@/pages/Login/LoginPage.vue'
import UnidadAcademicaPage from '@/pages/UnidadAcademica/UnidadAcademicaPage.vue'
import RolPage from '@/pages/Rol/RolPage.vue'
import UsuarioPage from '@/pages/Usuario/UsuarioPage.vue'


import ProfilePage from '@/pages/Profile/ProfilePage.vue'
import TipoReportePage from '@/pages/TipoReporte/TipoReportePage.vue'
import TipoReporteVerPage from '@/pages/TipoReporteVer/TipoReporteVerPage.vue'
import FasePage from '@/pages/Fase/FasePage.vue'

import GestionPage from '@/pages/Gestion/GestionPage.vue'
import AreaInvestigacionPage from '@/pages/AreaInvestigacion/AreaInvestigacionPage.vue'
import LineaInvestigacionPage from '@/pages/LineaInvestigacion/LineaInvestigacionPage.vue'
import EntregasPage from '@/pages/Entregas/EntregasPage.vue'
import PrincipalPage from '@/pages/Principal/PrincipalPage.vue'

import SemestrePage from '@/pages/Semestre/SemestrePage.vue'
import EspecialidadPage from '@/pages/Especialidad/EspecialidadPage.vue'
import NivelAcademicoPage from '@/pages/NivelAcademico/NivelAcademicoPage.vue'

import CriterioEvaluacionPage from '@/pages/CriterioEvaluacion/CriterioEvaluacionPage.vue'
import AsignacionPage from '@/pages/Asignacion/AsignacionPage.vue'
import FuncionPage from '@/pages/Funcion/FuncionPage.vue'
import EvaluacionPage from '@/pages/Evaluacion/EvaluacionPage.vue'
import MainPage from '@/pages/Main/MainPage.vue'

import EstudiantePage from '@/pages/Estudiante/EstudiantePage.vue'
import TipoMiembroPage from '@/pages/TipoMiembro/TipoMiembroPage.vue'
import MiembrosTribunalPage from '@/pages/MiembrosTribunal/MiembrosTribunalPage.vue'
import ProyectoPage from '@/pages/Proyecto/ProyectoPage.vue'
import DefensasPage from '@/pages/Defensas/DefensasPage.vue'
import TipoInformePage from '@/pages/TipoInforme/TipoInformePage.vue'
import DocumentacionPage from '@/pages/Documentacion/DocumentacionPage.vue'
import BitacoraPage from '@/pages/Bitacora/BitacoraPage.vue'
import ConsultaAvalPage from '@/pages/ConsultaAval/ConsultaAvalPage.vue'
import ManualUsuarioPage from '@/pages/ManualUsuario/ManualUsuarioPage.vue'
import CartaPage from '@/pages/Carta/CartaPage.vue'
import ManualActividadPage from '@/pages/ManualActividad/ManualActividadPage.vue'





Vue.use(Router)

export default new Router({
  routes: [
   {
      path: '/',
      name: 'MainPage',
      component: MainPage
    },
    {
      path: '/Principal',
      name: 'PrincipalPage',
      component: PrincipalPage
    },
    {
      path: '/UnidadAcademica',
      name: 'Unidad Academica',
      component: UnidadAcademicaPage
    },
    {
      path: '/Carta',
      name: 'Carta',
      component: CartaPage
    },
    
    {
      path: '/ManualActividad',
      name: 'ManualActividad',
      component: ManualActividadPage
    },
    {
      path: '/Rol',
      name: 'Rol',
      component: RolPage
    },
    {
      path: '/Usuario',
      name: 'Usuario',
      component: UsuarioPage
    },
    {
      path: '/Profile',
      name: 'Perfil',
      component: ProfilePage
    },
    {
      path: '/Login',
      name: 'Login',
      component: LoginPage
    },
    {
      path: '/TipoReporte',
      name: 'TipoReporte',
      component: TipoReportePage
    },
    {
      path: '/TipoReporteVer',
      name: 'TipoReporteVer',
      component: TipoReporteVerPage
    },
    {
      path: '/Fase',
      name: 'Fase',
      component: FasePage
    },
    
    
    {
      path: '/Gestion',
      name: 'Gestion',
      component: GestionPage
    },
    {
      path: '/AreaInvestigacion',
      name: 'AreaInvestigacion',
      component: AreaInvestigacionPage
    },
    {
      path: '/LineaInvestigacion',
      name: 'LineaInvestigacion',
      component: LineaInvestigacionPage
    },
    {
      path: '/Asignacion',
      name: 'Asignacion',
      component: AsignacionPage
    },
    {
      path: '/Entregas',
      name: 'Entregas',
      component: EntregasPage
    },
    {
      path: '/Semestre',
      name: 'Semestre',
      component: SemestrePage
    },
    {
      path: '/Especialidad',
      name: 'Especialidad',
      component: EspecialidadPage
    },
    {
      path: '/NivelAcademico',
      name: 'NivelAcademico',
      component: NivelAcademicoPage
    },
    
    
    {
      path: '/CriterioEvaluacion',
      name: 'CriterioEvaluacion',
      component: CriterioEvaluacionPage
    },
    {
      path: '/Evaluacion',
      name: 'Evaluacion',
      component: EvaluacionPage
    },
    {
      path: '/Funcion',
      name: 'Funcion',
      component: FuncionPage
    },
    {
      path: '/Main',
      name: 'Main',
      component: MainPage
    },
    {
      path: '/Estudiante',
      name: 'Estudiante',
      component: EstudiantePage
    },
    {
      path: '/TipoMiembro',
      name: 'TipoMiembro',
      component: TipoMiembroPage
    },
    {
      path: '/MiembrosTribunal',
      name: 'MiembrosTribunal',
      component: MiembrosTribunalPage
    },
    {
      path: '/Proyecto',
      name: 'Proyecto',
      component: ProyectoPage
    },
    {
      path: '/Defensas',
      name: 'Defensas',
      component: DefensasPage
    },

    {
      path: '/TipoInforme',
      name: 'TipoInforme',
      component: TipoInformePage
    },
    {
      path: '/Documentacion',
      name: 'Documentacion',
      component: DocumentacionPage
    },
    {
      path: '/Bitacora',
      name: 'Bitacora',
      component: BitacoraPage
    },
    {
      path: '/ConsultaAval',
      name: 'ConsultaAval',
      component: ConsultaAvalPage
    },
    {
      path: '/ManualUsuario',
      name: 'ManualUsuario',
      component: ManualUsuarioPage
    }
  ]
})