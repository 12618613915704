<template>
  <!-- Image and text  bg-transparent-->

<div>
  
    <div>
        <b-navbar toggleable="lg" type="dark"  class="fixed-top" style="background-color:#0F2C66; ; height: 80px;">
            <b-navbar-brand class="ml-5">
                 <router-link
                    class="waves-effect"
                    to="Main"
                    active-class="active"
                >
                    <img
                    src="/assets//images/emi_logo.png"
                    width="200"
                    height="60"
                    alt=""
                    />
                </router-link>
        </b-navbar-brand>
            

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav  type="dark"  class="fixed-down" style="background-color:#0F2C66; ; height: 80px; width:100%">

            <!-- Right aligned nav items #70a2ba  -->
            <b-navbar-nav class="ml-auto">
                 
               
                <!-- <b-nav-item v-if="auth.id == null" @click="abrirModalRegistro" >REGISTRARSE</b-nav-item> -->
                <!-- <b-nav-item v-if="auth.id == null" @click="">LOGIN</b-nav-item> -->
                <b-nav-item v-if="auth.id == null">
                     <router-link
                        class="waves-effect text-white"
                        to="Login"
                        dark
                        active-class="active"
                        style="text-decoration: none; background-color:#0F2C66;"
                        >
                        Iniciar Sesión
                    </router-link>
                 </b-nav-item>

                 <b-nav-item v-if="auth.Usuario">
                     <router-link
                        class="waves-effect text-white"
                        to="Entregas"
                        dark
                        active-class="active"
                        style="text-decoration: none;"
                        >
                        Volver Atras
                    </router-link>
                 </b-nav-item>

                
               <!--  <b-nav-item-dropdown text="OPCIONES DE BÚSQUEDA" right>
                    
                   <b-dropdown-item>
                        <router-link
                            class="btn btn-default"
                            to="Buscador"
                            active-class="active"
                        >
                            <v-icon>mdi-airplay</v-icon>
                            Búsqueda de Libros
                        </router-link>
                    </b-dropdown-item>

                    <b-dropdown-item>
                        <router-link
                            class="btn btn-default"
                            to="BuscadorBibliotecaVirtual"
                            active-class="active"
                        >
                            <v-icon>mdi-airplay</v-icon>
                            Bibliotecas Virtuales
                        </router-link>
                    </b-dropdown-item>
                    
                    <b-dropdown-item>
                        <router-link
                            class="btn btn-default"
                            to="BuscadorDiccionarioMilitar"
                            active-class="active"
                        >
                            <v-icon> mdi-wan </v-icon>
                            Diccionario Militar
                        </router-link>
                    </b-dropdown-item>

                </b-nav-item-dropdown> -->

                <b-nav-item-dropdown right v-if="auth.id != null">
                    
                <!-- Using 'button-content' slot -->
                <template #button-content>
                    <em>{{auth.Usuario}}</em>
                </template>
                <b-dropdown-item >
                    <router-link
                        class="waves-effect text-black"
                        to="Profile"
                        active-class="active"
                        >
                        Ver perfil
                    </router-link>
                </b-dropdown-item>
                <b-dropdown-item @click="cerrarSesion">Cerrar Sesion</b-dropdown-item>
                </b-nav-item-dropdown>

            </b-navbar-nav>
            </b-collapse>
        </b-navbar><br>
        <br>
        <br>
    </div>


</div>
    
  
  <!-- End Header -->
</template>


<script>
import MainService from "@/services/MainService.js";

export default {
    name: "MenuExterno",
  data() {
    
    return {
        ss : new MainService(),
        MenuExterno: {},
        errorBag: {},
        nuevaUsuario:{},
        loginCredenciales: {},
        auth: {},
        unidadAcademicas:[],
        mostrarVerPrograma:false,
    };
  },

  
  mounted() {
    this.auth = JSON.parse(localStorage.getItem("usuario"));
    console.log(this.auth);
    // this.getUnidadAcademica();
  },

    created(){
        this.$root.$refs.MenuExterno=this;
    },

	methods: {

		cancelUsuario(){
			this.nuevaUsuario = {};
			this.$refs['modalRegistro'].hide();
        },
        
        // pruebacomponente(){
        //     this.$root.$refs.VerProgramaPostgrado.prueba("sebas");
        // },

        /* pruebasofi(){
            console.log("holasofi");
        },
 */
        

        verificarCorreo() {
            this.ss.verificarCorreo(this.nuevaUsuario.email).then(
                (result) => {
                    // console.log(result);
                    let response = result.data;
                    console.log(response);
                    
                    if(response.success){
                        // console.log("el correo no existe");
                        this.buscarSaga();
                    }else{
                        // console.log("el correo existe");
                        this.$bvToast.toast(
                            'El correo ya se encuentra registrado',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Ocurrio un probela al verificar el correo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        
		saveUsuario() {
            this.ss.storeUsuarioExterno(this.nuevaUsuario).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
					)
					this.loginCredenciales.email = this.nuevaUsuario.email;
					this.loginCredenciales.password = this.nuevaUsuario.password;
					this.login();
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['modalRegistro'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
		},
		login() {
			this.ss.login(this.loginCredenciales)
				.then(
					(result) => {
						console.log(result);
						localStorage.setItem('usuario', JSON.stringify(result.data.data));
						localStorage.setItem('token', JSON.stringify(result.data.token));
						console.log("logueado");
						this.login= true;
						this.$refs['modalLogin'].hide();
                        this.usuario=result.data.data;
                        this.auth=result.data.data;
                        
                        this.loginCredenciales={};
						//this.$router.push('/');
						/*var usuario = JSON.parse(localStorage.getItem('usuario'));
						console.log(usuario)*/
					}
				)
				.catch((error) => {
					this.errorBag = error.response.data.errors;
					this.$bvToast.toast(
						'Problema al Iniciar Sesion',
						{
							title: 'Error',
							variant: 'danger',
							autoHideDelay: 5000
						}
					)
				});
        },
        cerrarSesion() {
            localStorage.clear();
            this.auth={};
            this.$router.push("/Main");
            if (this.$msal.isAuthenticated()) {
                this.$msal.signOut();
            }
        },
		abrirModalLogin(){
			// this.tiponivelacademico = {};
			this.$refs['modalLogin'].show();
			
        },
        abrirModaReg(){
			this.$refs['modalRegistro'].show();
			
		},
		abrirModalRegistro(){
			// this.tiponivelacademico = {};
			this.$refs['modalLogin'].hide();
            this.$refs['modalRegistro'].show();
        },
        /* getUnidadAcademica() {
            this.ss.listExternoUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    // this.unidadAcademicas = response.data;
                    let uas=response.data
                    this.unidadAcademicas = uas.splice(1, 4);
                }
            ).catch(error => {
                console.log(error);
            });
        }, */

        buscarSaga() {
			let params = {
				CI: this.nuevaUsuario.CI
			}
            this.ss.buscarSaga(params)
            .then(
                (result) => {
                    let response = result.data;
                    //Si encuentra al usuario...
                    if(response.success == true){
                        this.nuevaUsuario.CodigoSaga = response.data.CodAlumno;
                        this.nuevaUsuario.correoInstitucional = response.data.email_emi;
                        this.nuevaUsuario.Celular = response.data.celular;
                        this.nuevaUsuario.Telefono = response.data.Telefono;
                        this.nuevaUsuario.Direccion = response.data.Direccion;
                    }
                    this.saveUsuario();
                    console.log(response);
                }
            )
            .catch(error => {
                console.log(error);
            });
		},
    },


};
</script>

<style scoped>
.nombreLogin{
    padding: 10px 0 10px 25px;
    color: white;
}

</style><br>