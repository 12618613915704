import moment from "moment";
import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "CartaPage",
    data() {

        let ss = new MainService();
        return {
          
            date: new Date().toISOString().substr(0, 10),
            picker: new Date().toISOString().substr(0, 10),

            msg: "CartaPage",
            ss: ss,
            ajax: {
                "url": ss.indexCarta(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false},
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false },
                { data: 'Estudiante', name: 'te.Estudiante', title: 'Estudiante', width: '300px' },
                { data: 'Tutor', name: 'te.Tutor', title: 'Tutor'},
                { data: 'Especialidad', name: 'te.Especialidad', title: 'Especialidad'},
                { data: 'Semestre', name: 'te.Semestre', title: 'Semestre'},
                { data: 'Proyecto', name: 'te.Proyecto', title: 'Proyecto'},
                { data: 'JefeCarrera', name: 'te.JefeCarrera', title: 'JefeCarrera'},
              
                
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
           
            TipoInformes: [],
            Fases: [],
            selectedHitEst: 0,
            selectedHitTutor : null,
            carta: {
                Estudiante: "",
                Tutor:"",
            },

            carta2: {},
            Estudiantes: [],
            entregas2: {},
            Especialidades: [],
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,

            ex7: "0",
            auth : null,
            isLoading: false,
            isLoadingFile:false,
            errorBag: {}
        };
    },
    methods: {
        getEspecialidad() {
            this.ss.listEspecialidad().then(
                (result) => {
                    let response = result.data;
                    this.Especialidades = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
     
        
        newCarta() {
            this.carta = {};
            this.$refs['frm-carta'].show();
        },

        printCartaT() {
           
                this.ss.printCartaT(this.carta)
           
                    .then(
                        result => {
                            let response = result.data;
                            var urlFile = response.data;
                            var dir = this.rutaApp + urlFile.url;
                            window.open(dir);
                        })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    })
            
        },

        printCartaAT() {
           
            this.ss.printCartaAT(this.carta)
       
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
        
    },

        showCarta(id) {
            this.isLoading=true;
            this.ss.showCarta(id).then(
                (result) => {
                    let response = result.data;
                    this.carta = response.data;
                    this.$refs['view-carta'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editCarta() {
            this.$refs['frm-carta'].show();
            this.$refs['view-carta'].hide();
        },
        cancelCarta() {
            if (this.carta.id) {
                this.$refs['view-carta'].show();
            }
            
            this.$refs['frm-carta'].hide();
            this.$refs['view-carta'].hide();
            this.$refs['frm-edit'].hide();
        },
        saveCarta() {

            if(this.auth.Rol==5){
                this.carta.Estudiante = this.entregas2.Estudiante;
                this.carta.EmailEstudiante = this.entregas2.EmailEstudiante;
                this.carta.EmailDocente = this.entregas2.EmailDocente;
                this.carta.CI = this.entregas2.CI;
            }
            

            this.ss.storeCarta(this.carta).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-carta'].hide();
                    this.$refs['datatable-carta'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        update() {
         
            this.ss.update(this.carta).then(

              
                (result) => {
                    
                    console.log(result);
                   
                    let response = result.data;
                   
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        },
                        
                    )
                   
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
               
                    this.$refs['frm-edit'].hide();
                    this.$refs['datatable-carta'].reload();
                })
                .catch((error) => {
                    this.esBotonDeshabilitado = false,
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteCarta() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                buttons: true,
                dangerMode: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                        this.ss.destroyCarta(this.carta)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-carta'].hide();
                                this.$refs['datatable-carta'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
       
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
              // Validación del tipo de archivo
              if (input.files[0].type !== 'application/pdf') {
                this.$bvToast.toast(
                  'El archivo seleccionado no es un Documento PDF',
                  {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000
                  }
                );
                this.isLoadingFile = false;
                return;
              }
          
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]);
              var data = new FormData();
              data.append('File', input.files[0]);
              this.ss.uploadFile(data)
                .then(result => {
                  if (result.data.success) {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Correcto',
                        variant: 'info',
                        autoHideDelay: 5000
                      }
                    );
                    this.carta.DocumentoInforme = result.data.data;

                  } else {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 5000
                      }
                    )
                  }
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                });
            }
          }, 
          showEstudiante2(id) {
            //this.isLoading=true;
            this.ss.showEstudiante2(id).then(
                (result) => {
                    let response = result.data;
                    this.entregas2 = response.data;
                    //this.$refs['view-entregas'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

       /*    loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            this.carta.DocumentoInforme = result.data.data;

                            
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo el documento',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo el documento',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        }, */
       
        
        
        
        draw() {
            window.$('.btn-datatable-Carta').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showCarta(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    watch: {
            selectedHitEst(value) {
            this.carta.Estudiante = value.Estudiante;
            this.carta.EmailEstudiante = value.EmailEstudiante;
            this.carta.EmailDocente = value.EmailDocente;
            this.carta.CI = value.CI;
            this.carta.UnidadAcademica = value.UnidadAcademica;
            //console.log(value);
        },
        selectedHitTutor(value) {
            this.carta.Tutor = value.email;
            //console.log(value);
        },
    },
    filters: {
        moment(date) {
          return moment(date).format("DD-MM-YYYY");
        },
      },
    mounted() {
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        console.log(usuario);
        if (!usuario) {
          this.$router.push('/Login');
        }else{
           
   
            this.getEspecialidad();
            this.auth = usuario;
            
        }
    }
};
