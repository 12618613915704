<template>
  <!-- Image and text  bg-transparent-->
  <div>
    <link href="/assets/estilos/bootstrap/css/bootstrap.min.css" rel="stylesheet">
    <link href="/assets/estilos/icofont/icofont.min.css" rel="stylesheet">
    <link href="/assets/estilos/boxicons/css/boxicons.min.css" rel="stylesheet">
    <link href="/assets/estilos/remixicon/remixicon.css" rel="stylesheet">
    <link href="/assets/estilos/owl.carousel/assets/owl.carousel.min.css" rel="stylesheet">
    <link href="/assets/estilos/venobox/venobox.css" rel="stylesheet">
    
    <link href="/assets/css/style2.css" rel="stylesheet">
    
    <!-- ======= Footer ======= -->
    <footer id="footer" style="background-color: #0F2C66; height:200px;">

        <div class="footer-top">

        <div class="container">

            <div class="row  justify-content-center">
                <div class="col-lg-6">
                    <h3 class="mt-5 text-white " style="padding-top: 30px;" align="center"> - EMI - </h3>
                    <h4 class="mt-5 text-white" align="center">
                    <i >Escuela Militar de Ingeniería</i>
                    </h4></div>
            
            </div>

            

        </div>
        </div>

        <div class="container footer-bottom clearfix">
        


        
        </div>
    </footer>
    </div>
  <!-- End Header -->
</template>

<script>
export default {
    name: "FooterExterno",
    data() {
        return {
        MenuExterno: {},
                errorBag: {},
                nuevaUsuario:{},
                loginCredenciales: {},
        };
    },
    mounted() {

    },

	methods: {

    }
};
</script>