import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {

    data() {
        let ss = new MainService();
        return {
         
            ss: ss,
            ajax: {
                
                headers: ss.getToken(),
            },
            columns: [
               
            ],

           
            isLoading: false,
            errorBag: {},
            auth : null,
        };
    },
    methods: {
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        if (!usuario) {
          this.$router.push('/Login');
        }else{
            this.auth = usuario;
        }
    }
};