import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "FuncionPage",
    data() {
        let ss = new MainService();
        return {
            msg: "EspecialidadPage",
            ss: ss,
            ajax: {
                "url": ss.indexEspecialidad(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false },
                { data: 'Especialidad', name: 'Especialidad', title: 'Especialidad' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],

            especialidades: [],
            especialidad: {},
            funcions: [],
            funcion: {},
            auth : null,
            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
        newEspecialidad() {
            this.especialidad = {};
            this.$refs['frm-especialidad'].show();
        },
        showEspecialidad(id) {
            this.isLoading=true;
            this.ss.showEspecialidad(id).then(
                (result) => {
                    let response = result.data;
                    this.especialidad = response.data;
                    this.$refs['view-especialidad'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editEspecialidad() {
            this.$refs['frm-especialidad'].show();
            this.$refs['view-especialidad'].hide();
        },
        cancelEspecialidad() {
            if (this.especialidad.id) {
                this.$refs['view-especialidad'].show();
            }
            this.$refs['frm-especialidad'].hide();
        },
        saveEspecialidad() {
            this.ss.storeEspecialidad(this.especialidad).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-especialidad'].hide();
                    this.$refs['datatable-especialidad'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        saga() {
         
            this.ss.listarEspecialidad().then(
                (result) => {
                    let response = result.data;
                    this.especialidad = response.data;
                    location.reload();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },


        deleteEspecialidad() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                buttons: true,
                dangerMode: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                        this.ss.destroyEspecialidad(this.especialidad)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-especialidad'].hide();
                                this.$refs['datatable-especialidad'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-especialidad').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showEspecialidad(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        if (!usuario) {
          this.$router.push('/Login');
        }else{
            this.auth = usuario;
        }
    }
};
