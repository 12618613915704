import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "MiembrosTribunalPage",
    data() {
        let ss = new MainService();
        return {
            msg: "MiembrosTribunalPage",
            ss: ss,
            ajax: {
                "url": ss.indexMiembrosTribunal(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false },
                { data: 'Usuario', name: 'Usuario', title: 'Usuario', width: '200px' },
                {data: 'TipoMiembro', name: 'TipoMiembro', title: 'Tipo Miembro' },
                {data: 'TituloProyecto', name: 'TituloProyecto', title: 'Proyecto', width: '300px' },
               
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            Usuarios: [],
            TipoMiembros: [],
            Estudiantes: [],
            
            selectedHitUsuario: null,

            selectedHitProyecto: null,

            miembrostribunals: [],
            miembrostribunal: {
                Usuario: "",
                Proyecto: "",
            },
            isLoading: false,
            errorBag: {},
            auth : null,
        };
    },
    methods: {
        getUsuario() {
            this.ss.listUsuario().then(
                (result) => {
                    let response = result.data;
                    this.Usuarios = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getTipoMiembro() {
            this.ss.listTipoMiembro().then(
                (result) => {
                    let response = result.data;
                    this.TipoMiembros = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstudiante() {
            this.ss.listEstudiante().then(
                (result) => {
                    let response = result.data;
                    this.Estudiantes = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
       
        newMiembrosTribunal() {
            this.miembrostribunal = {};
            this.$refs['frm-miembrostribunal'].show();
        },
        showMiembrosTribunal(id) {
            this.isLoading=true;
            this.ss.showMiembrosTribunal(id).then(
                (result) => {
                    let response = result.data;
                    this.miembrostribunal = response.data;
                    this.$refs['view-miembrostribunal'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editMiembrosTribunal() {
            this.$refs['frm-miembrostribunal'].show();
            this.$refs['view-miembrostribunal'].hide();
        },
        cancelMiembrosTribunal() {
            if (this.miembrostribunal.id) {
                this.$refs['view-miembrostribunal'].show();
            }
            this.$refs['frm-miembrostribunal'].hide();
        },
        saveMiembrosTribunal() {
            this.ss.storeMiembrosTribunal(this.miembrostribunal).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-miembrostribunal'].hide();
                    this.$refs['datatable-miembrostribunal'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteMiembrosTribunal() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                buttons: true,
                dangerMode: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.ss.destroyMiembrosTribunal(this.miembrostribunal)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-miembrostribunal'].hide();
                                this.$refs['datatable-miembrostribunal'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                       
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-MiembrosTribunal').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showMiembrosTribunal(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    watch: {
        selectedHitUsuario(value) {
            this.miembrostribunal.Usuario = value.Usuario;
            //console.log(value);
        },
        selectedHitProyecto(value) {
            this.miembrostribunal.Proyecto = value.TituloProyecto;
            //console.log(value);
        },
    },
    mounted() {
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        if (!usuario) {
            this.$router.push('/Login');
        }else {
            this.getUsuario();
            this.getTipoMiembro();
            this.getEstudiante();
            this.auth = usuario;
            
        }
    }
};
