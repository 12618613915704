import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");


import moment from "moment";
import Vue from "vue";
import VueLocalStorage from "vue-ls"
Vue.use(VueLocalStorage)

import html2pdf from 'html2pdf.js'

//importing bootstrap 5 and pdf maker Modules
import "bootstrap/dist/css/bootstrap.min.css";
export default {
    name: "ProyectoPage",
    data() {
        let ss = new MainService();
        return {
            index: 1,
           
            date2: new Date(),
            picker: new Date().toISOString().substr(0, 0),

            msg: "ProyectoPage",
            ss: ss,
            ajax: {
                "url": ss.indexProyecto(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false, className: 'text-center' },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false , className: 'text-center'},
                { data: 'TituloProyecto', name: 'p.TituloProyecto', title: 'Proyecto', className: 'text-justify' },
                {data: 'AreaInvestigacion', name: 'ai.AreaInvestigacion', title: 'Area de Investigación' , className: 'text-justify'},
                {data: 'LineaInvestigacion', name: 'li.LineaInvestigacion', title: 'Linea de Investigación', className: 'text-justify' },
                {data: 'Estudiante', name: 'e.Estudiante', title: 'Estudiante' , className: 'text-center'},
                /* {data: 'Descripcion', name: 'pro.Descripcion', title: 'Descripcion' }, */
                {data: 'Gestion', name: 'g.Gestion', title: 'Gestión' , className: 'text-center'},
                {data: 'Especialidad', name: 'e.Especialidad', title: 'Especialidad', className: 'text-center' },
              
              
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false, className: 'text-center'
                },
            ],
            ajaxtemario: {
                "url": ss.indexTemario(),
                headers: ss.getToken(),
            },
            columnstemario: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false, className: 'text-center' },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false , className: 'text-center'},
                { data: 'Capitulo', name: 'te.Capitulo', title: 'Capitulos', className: 'text-justify' },
            
            
              
              
              
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false, className: 'text-center'
                },
            ],
           
          
         
            Estudiantes: [],
            Gestiones: [],
            Especialidades: [],
            tems: [],
            
            AreaInvestigacions: [],
            LineaInvestigacions: [],
           
            auth : null,
            selectedHitProyecto: null,
            selectedHitEst: 0,
            etapaSeleccionadaID: '',
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,
            mostrarInformacion: false,
            informacionEtapa: '',
            temario:{
                Capitulo: ''
            },
            temario3:{},
            proyectos: [],
            proyecto: {
                Etapa: ''
              },
            isLoading: false,
            errorBag: {},

            Fases: [],
            image:'',
            Proyectos: [],
            bitacora: {
                Estudiante:"",
             },
        };
    },
    computed: {
        cambioOpciones2() {
            // Aquí puedes definir la información correspondiente a cada opción
            switch (this.proyecto.Etapa) {
              case 'PERFIL Y TEMARIO':
                this.mostrarInformacion = true;
                this.proyecto.idEtapa = 1;
                break;
              case 'MARCO TEORICO':
                this.mostrarInformacion = true;
                this.proyecto.idEtapa = 2;
                break;
              case 'MARCO PRACTICO PRIMERA PARTE':
                this.mostrarInformacion = true;
                this.proyecto.idEtapa = 3;
                break;
              case 'MARCO PRACTICO COMPLETO':
                this.mostrarInformacion = true;
                this.proyecto.idEtapa = 4;
                break;
              case 'PRIMER BORRADOR':
                this.mostrarInformacion = true;
                this.proyecto.idEtapa = 5;
                break;
              case 'BORRADOR FINAL':
                this.mostrarInformacion = true;
                this.proyecto.idEtapa = 6;
                break;
              default:
                this.mostrarInformacion = false;
                this.proyecto.idEtapa = '';
                break;
            }},
        lineasInvestigacionFiltradas() {
          if (this.proyecto.AreaInvestigacion) {
            return this.LineaInvestigacions.filter(li => li.AreaInvestigacion === this.proyecto.AreaInvestigacion);
          } else {
            return [];
          }
        }
      },
    methods: {

        cambioOpciones2(){

            
            if(document.getElementById('opciones2').value == 1) {
                document.getElementById('show').value='INTRODUCCIÓN';
                document.getElementById('show2').value='ANTECEDENTES';
                document.getElementById('show3').value='PROBLEMA PRINCIPAL';
                document.getElementById('show4').value='PROBLEMAS SECUNDARIOS';
                document.getElementById('show5').value='OBJETIVO GENERAL';
                document.getElementById('show6').value='OBJETIVOS SECUNDARIOS';
                document.getElementById('show7').value='JUSTIFICACIÓN';
                document.getElementById('show8').value='LÍMITES';
                document.getElementById('show9').value='ALCANCES';
                document.getElementById('show10').value='TEMARIO TENTATIVO';
                document.getElementById('show11').value='';
                document.getElementById('show12').value='';
                
            }
            if(document.getElementById('opciones2').value == 2) {
                document.getElementById('show').value='Título 2.1';
                document.getElementById('show2').value='Título 2.2';
                document.getElementById('show3').value='Título 2.3';
                document.getElementById('show4').value='Título 2.4';
                document.getElementById('show5').value='Título 2.5';
                document.getElementById('show6').value='Título 2.6';
                document.getElementById('show7').value='Título 2.7';
                document.getElementById('show8').value='Título 2.8';
                document.getElementById('show9').value='Título 2.9';
                document.getElementById('show10').value='Título 2.10';
                document.getElementById('show11').value='';
                document.getElementById('show12').value='';
            }

            if(document.getElementById('opciones2').value == 3) {
                document.getElementById('show').value='PLAN DE DESARROLLO DE SOFTWARE';
                document.getElementById('show2').value='ANALISIS DE LA SITUACION ACTUAL';
                document.getElementById('show3').value='METODOLOGÍA DE DESARROLLO';
                document.getElementById('show4').value='DISEÑO DE LA BASE DE DATOS';
                document.getElementById('show5').value='';
                document.getElementById('show6').value='';
                document.getElementById('show7').value='';
                document.getElementById('show8').value='';
                document.getElementById('show9').value='';
                document.getElementById('show10').value='';
                document.getElementById('show11').value='';
                document.getElementById('show12').value='';
            }
            if(document.getElementById('opciones2').value == 4) {
                document.getElementById('show').value='PLAN DE DESARROLLO DE SOFTWARE';
                document.getElementById('show2').value='ANALISIS DE LA SITUACION ACTUAL';
                document.getElementById('show3').value='METODOLOGÍA DE DESARROLLO';
                document.getElementById('show4').value='DISEÑO DE LA BASE DE DATOS';
                document.getElementById('show5').value='DIAGRAMAS UML';
                document.getElementById('show6').value='EVALUACIÓN TÉCNICA';
                document.getElementById('show7').value='EVALUACIÓN ECONÓMICA';
                document.getElementById('show8').value='';
                document.getElementById('show9').value='';
                document.getElementById('show10').value='';
                document.getElementById('show11').value='';
                document.getElementById('show12').value='';
            }
            
            if(document.getElementById('opciones2').value == 5) {
                document.getElementById('show').value='CARATULAS';
                document.getElementById('show2').value='INDICES';
                document.getElementById('show3').value='DEDICATORIA';
                document.getElementById('show4').value='AGRADECIMIENTOS';
                document.getElementById('show5').value='CONCLUSIONES Y RECOMENDACIONES';
                document.getElementById('show6').value='RESUMEN EJECUTIVO';
                document.getElementById('show7').value='CAPITULO 1';
                document.getElementById('show8').value='CAPITULO 2';
                document.getElementById('show9').value='CAPITULO 3';
                document.getElementById('show10').value='CAPITULO 4';
                document.getElementById('show11').value='CAPITULO 5';
                document.getElementById('show12').value='ANEXOS';
               
            }
            if(document.getElementById('opciones2').value == 6) {
                document.getElementById('show').value='CARATULAS';
                document.getElementById('show2').value='INDICES';
                document.getElementById('show3').value='DEDICATORIA';
                document.getElementById('show4').value='AGRADECIMIENTOS';
                document.getElementById('show5').value='CONCLUSIONES Y RECOMENDACIONES';
                document.getElementById('show6').value='RESUMEN EJECUTIVO';
                document.getElementById('show7').value='CAPITULO 1';
                document.getElementById('show8').value='CAPITULO 2';
                document.getElementById('show9').value='CAPITULO 3';
                document.getElementById('show10').value='CAPITULO 4';
                document.getElementById('show11').value='CAPITULO 5';
                document.getElementById('show12').value='ANEXOS';
            }
        },
        printDate: function () {
            return new Date().toLocaleDateString();
          },
        reRender(){
            this.index++;
         },
        getProyecto() {
            this.ss.listProyecto().then(
                (result) => {
                    let response = result.data;
                    this.Proyectos= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getFase() {
            this.ss.listFase().then(
                (result) => {
                    let response = result.data;
                    this.Fases= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        exportToPDF () {
            html2pdf(this.$refs.document, {
                margin: 0.4,
                filename: 'Bitacora.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { dpi: 192, letterRendering: true },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
            })
        },
        firmar(){
            // let BtnEle = document.getElementById("firma");
            // let resEle = document.querySelector(".result");
            // BtnEle.addEventListener("click", () => {
            //     fetch(this.auth.URLFirma)
            //     .then(function (response) {
            //         return response.blob();
            //     })
            //     .then(function (blob) {
            //         resEle.innerHTML = "blob.size = " + blob.size + "<br>";
            //         resEle.innerHTML += "blob.type = " + blob.type + "<br>";
            //     });
            // });

            // let baseStr64=this.auth.Firma64;
            // return imgElem.setAttribute('src', "data:image/jpg;base64," + baseStr64);

            var image = new Image();
            image.src = 'data:image/png;base64,'+this.auth.Firma64;
            document.body.appendChild(image);
        },
        handleImage(e){
            const selectedImage = e.target.files[0];
            this.createBase64Image(selectedImage);


            
        },
        createBase64Image(fileObject) {
            const reader = new FileReader();

            reader.onload = (e)=> {
                this.image = e.target.result;
            };
            reader.readAsDataURL(fileObject);
        },

        filtrarLineasInvestigacion() {
            this.proyecto.LineaInvestigacion = ''; // Reiniciar la selección de línea de investigación al cambiar el área
          },
        aplicarFiltros(){

            let gest = this.proyecto.Gestion     
            let espe = this.proyecto.Especialidad
                   
            this.ajax.data = function(d){
                d.Especialidad = espe
                d.Gestion = gest
            }
            //console.log("prueba");
            this.$refs['datatable-proyecto'].reloadAjax(this.ajax);
        },
        getGestion() {
            this.ss.listGestion().then(
                (result) => {
                    let response = result.data;
                    this.Gestiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEspecialidad() {
            this.ss.listEspecialidad().then(
                (result) => {
                    let response = result.data;
                    this.Especialidades = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstudiante() {
            this.ss.listEstudiante().then(
                (result) => {
                    let response = result.data;
                    this.Estudiantes= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
       
        getAreaInvestigacion() {
            this.ss.listAreaInvestigacion().then(
                (result) => {
                    let response = result.data;
                    this.AreaInvestigacions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getLineaInvestigacion() {
            this.ss.listLineaInvestigacion().then(
                (result) => {
                    let response = result.data;
                    this.LineaInvestigacions = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        getTemario() {
            this.ss.listTemario().then(
                (result) => {
                    let response = result.data;
                    this.tems = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        newProyecto() {
            this.proyecto = {};
            this.$refs['frm-proyecto'].show();
        },
        newTemario() {
            this.temario = {};
            this.$refs['frm-temario'].show();
        },
        newTemario2() {
            this.temario = {};
            this.$refs['frm-temario2'].show();
        },
        newTemario3() {
            this.temario = {};
     
            this.$refs['frm-temario3'].show();
        },
        newCi() {
            this.temario = {};
     
            this.$refs['frm-carta'].show();
        },
        showProyecto(id) {
            this.isLoading=true;
            this.ss.showProyecto(id).then(
                (result) => {
                    let response = result.data;
                    this.proyecto = response.data;
                    this.$refs['frm-edit'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        showTemario(id) {
            this.isLoading=true;
            this.ss.showTemario(id).then(
                (result) => {
                    let response = result.data;
                    this.temario = response.data;
                    this.$refs['frm-temario2'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editProyecto() {
            this.$refs['frm-edit'].show();
            this.$refs['view-proyecto'].hide();
        },
        
       modalTribunal() {
            this.$refs['frm-tribunal'].show();
        },
        
        modalBitacora() {
            if(!this.proyecto.FechaDocumento){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese la fecha con la que será generado el documento, en el campo 'Fecha del Documento'",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }
            this.$refs['frm-bitacora'].show();
        },
        
       
        cancelProyecto() {
            if (this.proyecto.id) {
                this.$refs['view-proyecto'].show();
            }
            
            this.$refs['frm-proyecto'].hide();
            this.$refs['view-proyecto'].hide();
            this.$refs['frm-edit'].hide();
        },
        saveProyecto() {
            this.ss.storeProyecto(this.proyecto).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-proyecto'].hide();
                    this.$refs['datatable-proyecto'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        updateCorreosd() {
            this.ss.updateCorreosd(this.proyecto.DocenteTrabajoDeGrado).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                  /*   this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    ) */
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                  /*   this.$refs['frm-proyecto'].hide();
                    this.$refs['datatable-proyecto'].reload(); */
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                   /*  this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    ) */
                });
        },

        updateCorreos() {
            this.ss.updateCorreos(this.proyecto.Tutor).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                  /*   this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    ) */
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                  /*   this.$refs['frm-proyecto'].hide();
                    this.$refs['datatable-proyecto'].reload(); */
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                   /*  this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    ) */
                });
        },

        updateCorreosr() {
            this.ss.updateCorreosr(this.proyecto.Revisor).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                  /*   this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    ) */
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                  /*   this.$refs['frm-proyecto'].hide();
                    this.$refs['datatable-proyecto'].reload(); */
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                   /*  this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    ) */
                });
        },


        updateCorreosr2() {
            this.ss.updateCorreosr2(this.proyecto.Revisor2).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                  /*   this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    ) */
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                  /*   this.$refs['frm-proyecto'].hide();
                    this.$refs['datatable-proyecto'].reload(); */
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                   /*  this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    ) */
                });
        },

        saveTemario() {
            this.temario.idProyecto = this.proyecto.id;
            console.log("LOL2023",this.temario.idProyecto);
            this.ss.storeTemario(this.temario).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-temario2'].hide();
                    this.$refs['datatable-temario'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        insertarTabDespuesDeDosPuntos(event) {
            const texto = event.target.value;
            const indiceDosPuntos = texto.indexOf(':');
            if (indiceDosPuntos !== -1) {
                // Insertar tab después del ":" si hay texto después de él
                if (texto.length > indiceDosPuntos + 1) {
                    const textoModificado =
                        texto.slice(0, indiceDosPuntos + 1) + '\t' + texto.slice(indiceDosPuntos + 1).replace(/\s+/g, ' ');
                    this.temario.Capitulo = textoModificado;
                } else {
                    this.temario.Capitulo = texto.slice(0, indiceDosPuntos + 1) + '\t';
                }
            }
            this.convertirAMayusculas();
        },
    
        insertarTabDespuesDeNumeroPunto(event) {
            const texto = event.target.value;
            const regex = /(\d+)\.(\d+)/; // Expresión regular para encontrar un número seguido de punto y luego otro número
            const match = texto.match(regex);
        
            if (match) {
                const numeroDespuesDelPunto = match[2];
                const indicePunto = match.index + match[1].length + 1; // Índice del punto más el número antes del punto
                // Insertar tab después del número después del punto si hay texto después de él
                if (texto.length > indicePunto + numeroDespuesDelPunto.length) {
                    const textoModificado =
                        texto.slice(0, indicePunto + numeroDespuesDelPunto.length) + '\t' + texto.slice(indicePunto + numeroDespuesDelPunto.length).replace(/\s+/g, ' ');
                    this.temario.Capitulo = textoModificado;
                } else {
                    this.temario.Capitulo = texto.slice(0, indicePunto + numeroDespuesDelPunto.length) + '\t';
                }
            }
            this.convertirAMayusculas();
        }
        
          ,
        
        
        convertirAMayusculas() {
            this.temario.Capitulo = this.temario.Capitulo.toUpperCase();
        },
        saveTemario2() {
            this.temario.idProyecto = this.proyecto.id;
            console.log("LOL2023",this.temario.idProyecto);
            this.ss.storeTemario(this.temario).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-temario3'].hide();
                    this.$refs['datatable-temario'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        saveSeccion() {
            this.temario3.idProyecto = this.proyecto.id;
            this.ss.storeSeccion(this.temario3).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-temario3'].hide();
                    this.$refs['datatable-temario'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        updateEtapa() {
            this.ss.updateEtapa(this.proyecto).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-edit'].hide();
                    this.$refs['frm-edit'].show();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
      /*   mostrarIdEtapa() {
            var select = document.getElementById("faseSelect");
            var input = document.getElementById("etapaIdInput");
            var idEtapa = select.value; // El valor seleccionado en el select es el ID de la etapa
            input.value = idEtapa;
            this.proyecto.idEtapa = input.value;
        }, */
     
        printProyecto() {
            /* if(!this.proyecto.Cite){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el Cite con el que será generado el documento, en el campo 'Cite'",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            } */

            if(!this.proyecto.FechaHoraDefensa){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese la fecha y hora de la defensa",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

            if(!this.proyecto.FechaDocumento){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese la fecha con la que será generado el documento, en el campo 'Fecha del Documento'",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

            if(!this.proyecto.Etapa){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor elija la fase del Trabajo de Grado ",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

            if(!this.proyecto.JefeCarrera){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el nombre del jefe de la especialidad de "+this.proyecto.Especialidad,
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }


        if(this.proyecto.NivelAcademico === 1 ){

            if(this.proyecto.idEtapa === 1 || this.proyecto.idEtapa === 2 || this.proyecto.idEtapa === 3){
                this.ss.printProyectoLic1(this.proyecto.id)
                //console.log(this.proyecto.id)
                    .then(
                        result => {
                            let response = result.data;
                            var urlFile = response.data;
                            var dir = this.rutaApp + urlFile.url;
                            window.open(dir);
                        })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    })
            }

            if(this.proyecto.idEtapa === 4 || this.proyecto.idEtapa === 5){
                this.ss.printProyectoLic1T(this.proyecto.id)
                //console.log(this.proyecto.id)
                    .then(
                        result => {
                            let response = result.data;
                            var urlFile = response.data;
                            var dir = this.rutaApp + urlFile.url;
                            window.open(dir);
                        })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    })
            }


            if(this.proyecto.idEtapa === 6){
                this.ss.printProyectoLic(this.proyecto.id)
                //console.log(this.proyecto.id)
                    .then(
                        result => {
                            let response = result.data;
                            var urlFile = response.data;
                            var dir = this.rutaApp + urlFile.url;
                            window.open(dir);
                        })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    })
            
            }

               
        }
            
            if(this.proyecto.NivelAcademico === 2 || this.proyecto.NivelAcademico === 5) {  
                if(this.proyecto.idEtapa === 1 || this.proyecto.idEtapa ===2 || this.proyecto.idEtapa === 3){
                    this.ss.printProyecto(this.proyecto.id)
                    //console.log(this.proyecto.id)
                        .then(
                            result => {
                                let response = result.data;
                                var urlFile = response.data;
                                var dir = this.rutaApp + urlFile.url;
                                window.open(dir);
                            })
                        .catch(error => {
                            console.log(error);
                            this.isLoading = false;
                        })
                }

                if(this.proyecto.idEtapa === 4 || this.proyecto.idEtapa ===5 || this.proyecto.idEtapa === 6){
                    this.ss.printProyectoT(this.proyecto.id)
                    //console.log(this.proyecto.id)
                        .then(
                            result => {
                                let response = result.data;
                                var urlFile = response.data;
                                var dir = this.rutaApp + urlFile.url;
                                window.open(dir);
                            })
                        .catch(error => {
                            console.log(error);
                            this.isLoading = false;
                        })
                }
               
            }

           
            
            
            
        },

        printTemario() {
            if(!this.proyecto.Cite){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el Cite con el que será generado el documento, en el campo 'Cite'",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }
            if(!this.proyecto.Objeto){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el CObjeto con el que será generado el documento, en el campo 'Cite'",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }
            if(!this.proyecto.Anexos){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el Anexos con el que será generado el documento, en el campo 'Cite'",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }
            if(!this.proyecto.DirectorUA){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el DirectorUA con el que será generado el documento, en el campo 'Cite'",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

          

                this.ss.printTemario(this.proyecto)
           
                    .then(
                        result => {
                            let response = result.data;
                            var urlFile = response.data;
                            var dir = this.rutaApp + urlFile.url;
                            window.open(dir);
                        })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    })
          
         

           
            
            
            
        },
        printMemoDefensa() {
            if(!this.proyecto.Cite){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el Cite con el que será generado el documento, en el campo 'Cite'",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

            if(!this.proyecto.FechaDocumento) { 
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese la Fecha del Documento con la que será generado el documento",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

      /*       if(!this.proyecto.FechaEmpastado) { 
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese la Fecha de Presentación del Empastado con la que será generado el documento",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            } */
          
            if(!this.proyecto.DirectorUA){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el DirectorUA con el que será generado el documento",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

                this.ss.printMemoDefensa    (this.proyecto)
           
                    .then(
                        result => {
                            let response = result.data;
                            var urlFile = response.data;
                            var dir = this.rutaApp + urlFile.url;
                            window.open(dir);
                        })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    })
          
         

           
            
            
            
        },
        printEmpastado() {
            if(!this.proyecto.Cite){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el Cite con el que será generado el documento, en el campo 'Cite'",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

            if(!this.proyecto.FechaDocumento) { 
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese la Fecha del Documento con la que será generado el documento",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

          if(!this.proyecto.FechaEmpastado) { 
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese la Fecha de Presentación del Empastado con la que será generado el documento",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            } 
          
            if(!this.proyecto.JefeCarrera){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el nombre del jefe de la carrera ",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }
            if(this.proyecto.NivelAcademico === 1 ){
                this.ss.printEmpastado    (this.proyecto)
           
                    .then(
                        result => {
                            let response = result.data;
                            var urlFile = response.data;
                            var dir = this.rutaApp + urlFile.url;
                            window.open(dir);
                        })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    })
            }
            if(this.proyecto.NivelAcademico === 2 ){
                this.ss.printEmpastadoT    (this.proyecto)
           
                    .then(
                        result => {
                            let response = result.data;
                            var urlFile = response.data;
                            var dir = this.rutaApp + urlFile.url;
                            window.open(dir);
                        })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    })
            }
                
          
         

           
            
            
            
        },

        printMemoTutor() {
            if(!this.proyecto.Cite){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el Cite con el que será generado el documento, en el campo 'Cite'",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

            if(!this.proyecto.FechaDocumento) { 
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese la Fecha del Documento con la que será generado el documento",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

        
            if(!this.proyecto.JefeCarrera){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el nombre del jefe de la carrera ",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }
  
                this.ss.printMemoTutor(this.proyecto)
           
                    .then(
                        result => {
                            let response = result.data;
                            var urlFile = response.data;
                            var dir = this.rutaApp + urlFile.url;
                            window.open(dir);
                        })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    })
                     
          
         

           
            
            
            
        },

        printCarta() {
          
            if(!this.proyecto.Cite){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el Cite con el que será generado el documento, en el campo 'Cite'",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }
            if(!this.proyecto.Objeto){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el CObjeto con el que será generado el documento, en el campo 'Cite'",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }
            if(!this.proyecto.Anexos){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el Anexos con el que será generado el documento, en el campo 'Cite'",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }
            if(!this.proyecto.DirectorUA){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el DirectorUA con el que será generado el documento, en el campo 'Cite'",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }
            this.ss.printCarta(this.proyecto)
       
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
      
     

       
        
        
        
    },
        
        
        printNota() {

           /*  if(!this.proyecto.Cite){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el Cite con el que será generado el documento, en el campo 'Cite'",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            } */
            if(!this.proyecto.FechaDocumento){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese la fecha con la que será generado el documento, en el campo 'Fecha del Documento'",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }
            if(!this.proyecto.FechaHoraDefensa){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese la fecha y hora de la defensa",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

            if(!this.proyecto.Etapa){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor elija la fase del Trabajo de Grado ",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }
            if(!this.proyecto.JefeCarrera){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el nombre del jefe de la especialidad de "+this.proyecto.Especialidad,
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

            if(this.proyecto.NivelAcademico === 1){
                if(this.proyecto.idEtapa === 1 || this.proyecto.idEtapa === 2 || this.proyecto.idEtapa === 3 || this.proyecto.idEtapa === 4 || this.proyecto.idEtapa === 5){
                    this.ss.printNotaLic1(this.proyecto.id)
                    //console.log(this.proyecto.id)
                        .then(
                            result => {
                                let response = result.data;
                                var urlFile = response.data;
                                var dir = this.rutaApp + urlFile.url;
                                window.open(dir);
                            })
                        .catch(error => {
                            console.log(error);
                            this.isLoading = false;
                        })
                }
                
                
                if(this.proyecto.idEtapa === 6){
                    this.ss.printNotaLic(this.proyecto.id)
                    //console.log(this.proyecto.id)
                        .then(
                            result => {
                                let response = result.data;
                                var urlFile = response.data;
                                var dir = this.rutaApp + urlFile.url;
                                window.open(dir);
                            })
                        .catch(error => {
                            console.log(error);
                            this.isLoading = false;
                        })
                
                }
            }

            if(this.proyecto.NivelAcademico === 2 || this.proyecto.NivelAcademico === 5) {  
                this.ss.printNota(this.proyecto.id)
                //console.log(this.proyecto.id)
                    .then(
                        result => {
                            let response = result.data;
                            var urlFile = response.data;
                            var dir = this.rutaApp + urlFile.url;
                            window.open(dir);
                        })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    })
            }

            



        },
        showUsuario(id) {
            this.isLoading=true;
            this.ss.showUsuario(id).then(
                (result) => {
                    let response = result.data;
                    this.auth = response.data;
                 
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        printAval() {
          /*   if (!this.auth.Firma) {
                try {
                    const profileRoute = '/Profile';  // Guarda la ruta en una variable aparte
                    this.$router.push(profileRoute);  // Espera a que la redirección se complete antes de continuar
            
                    this.$swal({
                        title: "Error al Guardar, datos incompletos",
                        text: `Por favor antes de generar el Aval cargue su firma digital en la pestaña de Perfil ${profileRoute}`,
                        icon: "warning",
                        dangerMode: true,
                    });
                    return;
                } catch (error) {
                    console.log(error);  // Maneja cualquier error que pueda ocurrir durante la redirección
                }
            } */
            if(!this.proyecto.FechaDocumento){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese la fecha con la que será generado el documento, en el campo 'Fecha del Documento'",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

            if(!this.proyecto.Etapa){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor elija la fase del Trabajo de Grado ",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }
            if(!this.proyecto.JefeCarrera){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese el nombre del jefe de la especialidad de "+this.proyecto.Especialidad,
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }


            this.proyecto.Usuario = this.auth.Usuario;
            this.proyecto.URLFirma = this.auth.URLFirma; 
   /*          this.proyecto.Firma = this.auth.Firma; */
            this.proyecto.emailLogueadoPersonal = this.auth.email;
            this.proyecto.emailLogueadoEmi = this.auth.CorreoEmi;

            this.ss.printAval(this.proyecto)
          
            //console.log(this.proyecto.id)
                .then(
                    result => {
                        let response = result.data;
                        var urlFile = response.data;
                        var dir = this.rutaApp + urlFile.url;
                        window.open(dir);
                    })
                .catch(error => {
                    console.log(error);
                    this.isLoading = false;
                })
       
        },

        printInformer() {
            if(!this.proyecto.FechaDocumento){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese la fecha con la que será generado el documento, en el campo 'Fecha del Documento'",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

            if(!this.proyecto.Etapa){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor elija la fase del Trabajo de Grado ",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }

              if(!this.proyecto.Modalidad){
                  this.$swal({
                      title: "Error al Guardar , datos incompletos",
                      text: "Por favor ingrese la Modalidad de Grado",
                      icon: "warning",
                      dangerMode: true,
                  })
                  return;
              }
              if(!this.proyecto.FechaHoraDefensa){
                this.$swal({
                    title: "Error al Guardar , datos incompletos",
                    text: "Por favor ingrese la Fecha y Hora de la Defensa",
                    icon: "warning",
                    dangerMode: true,
                })
                return;
            }
  
              if(this.proyecto.idEtapa === 1){
                this.ss.printInformer(this.proyecto)
            
                //console.log(this.proyecto.id)
                    .then(
                        result => {
                            let response = result.data;
                            var urlFile = response.data;
                            var dir = this.rutaApp + urlFile.url;
                            window.open(dir);
                        })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    })
            
            }

            if(this.proyecto.idEtapa === 2){
                this.ss.printInformerMT(this.proyecto)
            
                //console.log(this.proyecto.id)
                    .then(
                        result => {
                            let response = result.data;
                            var urlFile = response.data;
                            var dir = this.rutaApp + urlFile.url;
                            window.open(dir);
                        })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    })
            
            }

            if(this.proyecto.idEtapa === 3 || this.proyecto.idEtapa === 4){
                this.ss.printInformerMP(this.proyecto)
            
                //console.log(this.proyecto.id)
                    .then(
                        result => {
                            let response = result.data;
                            var urlFile = response.data;
                            var dir = this.rutaApp + urlFile.url;
                            window.open(dir);
                        })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    })
            
            }

            if(this.proyecto.idEtapa === 5){
                this.ss.printInformerB(this.proyecto)
            
                //console.log(this.proyecto.id)
                    .then(
                        result => {
                            let response = result.data;
                            var urlFile = response.data;
                            var dir = this.rutaApp + urlFile.url;
                            window.open(dir);
                        })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    })
            
            }

            if(this.proyecto.idEtapa === 6){
                this.ss.printInformerBF(this.proyecto)
            
                //console.log(this.proyecto.id)
                    .then(
                        result => {
                            let response = result.data;
                            var urlFile = response.data;
                            var dir = this.rutaApp + urlFile.url;
                            window.open(dir);
                        })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                    })
            
            }
        
             
         
          },

          
        deleteProyecto() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                buttons: true,
                dangerMode: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.ss.destroyProyecto(this.proyecto)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['frm-edit'].hide();
                                this.$refs['datatable-proyecto'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                       
                    }
                });
        },
        deleteTemario() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                buttons: true,
                dangerMode: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.ss.destroyTemario(this.temario)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['frm-temario2'].hide();
                                this.$refs['datatable-temario'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                       
                    }
                });
        },
        deleteSeccion() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                buttons: true,
                dangerMode: true,
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.ss.destroySeccion(this.temario3)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['frm-temario3'].hide();
                                this.$refs['datatable-temario'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                       
                    }
                });
        },
        draw() {
            window.$('.btn-datatable-Proyecto').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showProyecto(data);
            });

            window.$('.btn-datatable-Temario').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showTemario(data);
            });
        },
        
    },
    components: {
        dataTable,
        Loader
    },
    filters: {
        moment(date) {
          return moment(date).format("DD-MM-YYYY");
        },
      },
    watch: {

        /* cambioId(newVal) {
            // Obtener el ID de la etapa seleccionada
            const etapaId = document.querySelector(`option[value="${newVal}"]`).id;
            this.proyecto.EtapaId = etapaId;
          }, */
        selectedHitProyecto(value) {
            this.proyecto.Proyecto = value.TituloProyecto;
            //console.log(value);
        },
        selectedHitEst(value) {

            this.proyecto.Estudiante = value.Estudiante;
            this.proyecto.idEspecialidad = value.idEspecialidad;
            this.proyecto.Proyecto = value.TituloProyecto;
            this.proyecto.Gestion = value.Gestion;
            this.proyecto.Especialidad = value.Especialidad;
            this.proyecto.NivelAcademico = value.NivelAcademico;
            this.proyecto.Tutor = value.Tutor;
            this.proyecto.Revisor = value.Revisor;
            this.proyecto.Revisor2 = value.Revisor2;
            this.proyecto.DocenteTrabajoDeGrado = value.DocenteTrabajoDeGrado;
            this.proyecto.EmailEstudiante = value.EmailEstudiante;
            this.proyecto.EmailDocente = value.EmailDocente;
            this.proyecto.EmailTutor = value.EmailTutor;
            this.proyecto.EmailRevisor = value.EmailRevisor;
            this.proyecto.EmailRevisor2 = value.EmailRevisor2;
            this.proyecto.UnidadAcademica = value.UnidadAcademica;
            //console.log(value);
        },
    },
    mounted() {
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        if (!usuario) {
            this.$router.push('/Login');
        }else {
            this.getEstudiante();
            this.getAreaInvestigacion();
            this.getLineaInvestigacion();
            this.getTemario();
            this.auth = usuario;
            this.getGestion();
            this.getEspecialidad();
            this.showUsuario(this.auth.id);
            
          /*   this.correosIntervald = setInterval(this.updateCorreosd, 5000);
            this.correosInterval = setInterval(this.updateCorreos, 5000);
            this.correosIntervalr = setInterval(this.updateCorreosr, 5000);
            this.correosIntervalr2 = setInterval(this.updateCorreosr2, 5000); */



        
           
        }
    }
};
