import moment from "moment";
import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");
import Vue from 'vue';
import { PdfViewerPlugin, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner } from '@syncfusion/ej2-vue-pdfviewer';
Vue.use(PdfViewerPlugin);

import html2pdf from 'html2pdf.js'
export default {
    name: "EntregasPage",
    data() {

        let ss = new MainService();
        return {
          
            date: new Date().toISOString().substr(0, 10),
            picker: new Date().toISOString().substr(0, 10),
            fileAgentConfig: {
              accept: ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
              previewImage: true,
              previewFile: true,
              downloadIcon: true,
              deleteIcon: true,
            },
            msg: "EntregasPage",
            ss: ss,
            ajax: {
                "url": ss.indexEntregas(),
                headers: ss.getToken(),
                /*data: function(d){
                    d.prueba = 12
                }*/
            },

            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false, className: 'text-center' },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false, className: 'text-center' },
                { data: 'NombreEntrega', name: 'py.NombreEntrega', title: 'Nombre de la Entrega', width: '200px', className: 'text-justify'},
                { data: 'Estudiante', name: 'e.Estudiante', title: 'Estudiante', className: 'text-center'},
                { data: 'Fase', name: 'f.Fase', title: 'Fase', width: '100px', className: 'text-center'},
                { data: 'FechaEntrega', name: 'py.FechaEntrega', title: 'Fecha de la Entrega', className: 'text-center'},
                { data: 'FechaDevolucionDocente', name: 'py.FechaDevolucionDocente', title: 'Fecha Revisión Docente', className: 'text-center'},
                { data: 'FechaDevolucionTutor', name: 'py.FechaDevolucionTutor', title: 'Fecha Revisión Tutor', className: 'text-center'},
                { data: 'FechaDevolucionRevisor', name: 'py.FechaDevolucionRevisor', title: 'Fecha Revisión Revisor', className: 'text-center'},
                { data: 'FechaDevolucionRevisor2', name: 'py.FechaDevolucionRevisor2', title: 'Fecha Revisión Revisor 2', className: 'text-center'},
                { data: 'Gestion', name: 'g.Gestion', title: 'Gestión', width: '100px', className: 'text-center'},
                { data: 'Revisor2', name: 'py.Revisor2', title: 'Revisor2', width: '100px',visible: false, className: 'text-center'},
                //{ data: 'Usuario', name: 'p.Usuario', title: 'Responsable' },
                
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false, className: 'text-center'
                },
            ],
            ajaxadm: {
              "url": ss.indexAdmEntregas(),
              headers: ss.getToken(),
              /*data: function(d){
                  d.prueba = 12
              }*/
          },
            columnsadm: [
              { data: 'id', name: 'id', orderable: false, searchable: false, visible: false, className: 'text-center' },
              { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false, className: 'text-center' },
              { data: 'DocenteTrabajoDeGrado', name: 'ae.DocenteTrabajoDeGrado', title: 'DocenteTG', width: '200px', className: 'text-justify'},
              { data: 'Especialidad', name: 'ae.Especialidad', title: 'Especialidad', className: 'text-center'},
              { data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'UnidadAcademica', width: '100px', className: 'text-center'},
              { data: 'Gestion', name: 'ae.Gestion', title: 'Gestion', className: 'text-center'},
             /*  {
                data: 'Activo',
                name: 'ae.Activo',
                title: 'Activo',
                width: '100px',
                className: 'text-center',
                render: function(data) {
                    if (data === true || data === '1') {
                        return '<i class="fas fa-check"></i>'; // Símbolo de verificación
                    } else {
                        return '<i class="fas fa-times"></i>'; // "x"
                    }
                }
            }, */
              { data: 'FechaHora', name: 'ae.FechaHora', title: 'Fecha y Hora limite', className: 'text-center'}, 

            
              //{ data: 'Usuario', name: 'p.Usuario', title: 'Responsable' },
              
              {
                  data: 'action',
                  orderable: false,
                  title: 'Acciones',
                  searchable: false, className: 'text-center'
              },
             
          ],
           
            
            Fases: [],
            Usuarios: [],
            filas: [],
            selectedHitEst : 0,
            selectedHitDoc : null,
            selectedHit100 : null,
            selectedHitTutor : null,
            selectedHitRevisor : null,

            Gestiones: [],
            Especialidades: [],

            entregass:[],
            fechaHoraActual: '',
            admentregas:{
              ObservacionTutor: ''
            },
            entregas: {
                Estudiante:"",
                Docente:"",
                Tutor:"",
                Revisor:"",
                Revisor2:""
            },
            entregas2: {},
            entregas100: {},
            Estudiantes: [],
           Docentes: [],
            esBotonDeshabilitado: null,
            serviceUrl:"https://ej2services.syncfusion.com/production/web-services/api/pdfviewer",
            documentPath:"PDF_Succinctly.pdf",
            rutaApp:process.env.VUE_APP_MAIN_SERVICE,

            ex7: "0",
            auth : null,
            isLoading: false,
            isLoadingFile:false,
            errorBag: {},

        };
    },
    methods: {
        /*ShowSelected()
            {
            
            var cod = document.getElementById("producto").value;
            document.getElementById('showId2').value= cod
            var cod = document.getElementById("producto").value;
            document.getElementById('showId2').value= cod

            

            },*/
           
        aplicarFiltros(){
         
            let fase = this.entregas.Fase
            let estu = this.entregas.Estudiante           
            let gest = this.entregas.Gestion     
            let espe = this.entregas.Especialidad 
            
            this.ajax.data = function(d){
                d.Fase = fase
                d.Estudiante = estu
                d.Especialidad = espe
                d.Gestion = gest
            }
            //console.log("prueba");
            this.$refs['datatable-entregas'].reloadAjax(this.ajax);
        },

        limpiar(){
           
            document.getElementById("Fasee").value = "";
            this.$refs['datatable-entregas'].reloadAjax();
        },
         printDate: function () {
            return new Date().toLocaleDateString();
          },

         getFase() {
            this.ss.listFase().then(
                (result) => {
                    let response = result.data;
                    this.Fases= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEstudiante() {
            this.ss.listEstudiante().then(
                (result) => {
                    let response = result.data;
                    this.Estudiantes= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getDocente() {
          this.ss.listUDocente().then(
              (result) => {
                  let response = result.data;
                  this.Docentes= response.data;
              }
          ).catch(error => {
              console.log(error);
          });
      },
        getUsuario() {
            this.ss.listUsuario().then(
                (result) => {
                    let response = result.data;
                    this.Usuarios= response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getGestion() {
            this.ss.listGestion().then(
                (result) => {
                    let response = result.data;
                    this.Gestiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getEspecialidad() {
            this.ss.listEspecialidad().then(
                (result) => {
                    let response = result.data;
                    this.Especialidades = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        newEntregas() {
            this.entregas = {};
            this.$refs['frm-entregas'].show();
        },

         
        newAdmEntregas() {
          this.admentregas = {};
          this.$refs['frm-AdmEntregas'].show();
      },
      newAdmEntregas2() {
        this.admentregas = {};
        this.$refs['frm-AdmEntregas2'].show();
    },

        newAdmEntregasJ() {
          this.admentregas = {};
          this.$refs['frm-AdmEntregasj'].show();
      },
      newAdmEntregas4() {
        this.admentregas = {};
        this.$refs['frm-AdmEntregasjj'].show();
    },
  
        showEntregas(id) {
            this.isLoading=true;
            this.ss.showEntregas(id).then(
                (result) => {
                    let response = result.data;
                    this.entregas = response.data;
                    this.$refs['view-entregas'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        showAdmEntregas(id) {
          this.isLoading=true;
          this.ss.showAdmEntregas(id).then(
              (result) => {
                  let response = result.data;
                  this.admentregas = response.data;
                  this.$refs['frm-AdmEntregas2'].show();
                  this.isLoading=false;
              }
          ).catch(error => {
              console.log(error);
              this.isLoading=false;
          });
      },

        showEstudiante2(id) {
            //this.isLoading=true;
            this.ss.showEstudiante2(id).then(
                (result) => {
                    let response = result.data;
                    this.entregas2 = response.data;
                    //this.$refs['view-entregas'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        showEstudianteE(estudiante) {
  
          //this.isLoading=true;
          this.ss.showEstudianteE(estudiante).then(
              (result) => {
                  let response = result.data;
                  this.entregas100 = response.data;
                  this.isLoading = false;
              }
          ).catch(error => {
              console.log(error);
              this.isLoading = false;
          });
      },
      
   /*      descargarWord(){
  

            this.entregas.DocumentoWord.link.download;
        }, */
        exportToPDF () {
          html2pdf(this.$refs.document, {
              margin: 0.4,
              filename: 'Bitacora.pdf',
              image: { type: 'jpeg', quality: 0.98 },
              html2canvas: { dpi: 192, letterRendering: true },
              jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
          })
      },
        editEntregas() {
            this.$refs['frm-edit'].show();
            this.$refs['view-entregas'].hide();
        },
        cancelEntregas() {
            if (this.entregas.id) {
                this.$refs['view-entregas'].hide();
            }
            this.$refs['frm-entregas'].hide();
            this.$refs['view-entregas'].hide();
            this.$refs['frm-edit'].hide();
        },
        saveEntregas() {

          if(!this.entregas.NombreEntrega){
            this.$swal({
                title: "Error al Guardar, datos incompletos",
                text: "Ingrese el nombre de la Entrega del Trabajo de Grado",
                icon: "warning",
                dangerMode: true,
            })
            return;
        }
        if(!this.entregas.Fase){
          this.$swal({
              title: "Error al Guardar, datos incompletos",
              text: "Seleccione la fase del Trabajo de Grado",
              icon: "warning",
              dangerMode: true,
          })
          return;
      }

      
          if(!this.entregas.DocumentoWord){
            this.$swal({
                title: "Error al Guardar, datos incompletos",
                text: "Por favor cargar el Documento en Word",
                icon: "warning",
                dangerMode: true,
            })
            return;
        }

        

        if(!this.entregas.Documento){
          this.$swal({
              title: "Error al Guardar, datos incompletos",
              text: "Por favor cargar el Documento en PDF",
              icon: "warning",
              dangerMode: true,
          })
          return;
      }
            this.esBotonDeshabilitado = true;
            
            this.entregas.Estudiante = this.entregas2.Estudiante;
            this.entregas.EmailEstudiante = this.entregas2.EmailEstudiante;
            this.entregas.Docente = this.entregas2.DocenteTrabajoDeGrado;
            this.entregas.EmailDocente = this.entregas2.EmailDocente;
            this.entregas.Tutor = this.entregas2.Tutor;
            this.entregas.EmailTutor = this.entregas2.EmailTutor;
            this.entregas.Revisor = this.entregas2.Revisor;
            this.entregas.EmailRevisor = this.entregas2.EmailRevisor;
            this.entregas.Revisor2 = this.entregas2.Revisor2;
            this.entregas.EmailRevisor2 = this.entregas2.EmailRevisor2;
            this.entregas.Gestion = this.entregas2.Gestion;
            this.entregas.UnidadAcademica = this.entregas2.UnidadAcademica;
            this.entregas.Especialidad = this.entregas2.Especialidad;
            this.entregas.idEspecialidad = this.entregas2.idEspecialidad;

            if(this.entregas.NombreEntrega != null && this.entregas.Fase != null && this.entregas.Documento != null ){
              this.$swal.fire({
                title: '<strong>Espere un momento su entrega se esta enviando</strong>',
                icon: 'info',
              });
            }
           
            this.ss.storeEntregas(this.entregas).then(

              
                (result) => {
                    
                    console.log(result);
                   
                    let response = result.data;
                    this.esBotonDeshabilitado = false;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        },
                        
                    )
                   
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
               
                    this.$refs['frm-entregas'].hide();
                    this.$refs['datatable-entregas'].reload();

                    setTimeout(() => {
                      this.$swal.close();
                  },1000)
                    
                })
                .catch((error) => {
                    this.esBotonDeshabilitado = false,
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        obtenerFechaHoraActual() {
          const fechaHora = new Date();
      
          // Obtén los componentes de la fecha y hora
          const year = fechaHora.getFullYear();
          const month = ('0' + (fechaHora.getMonth() + 1)).slice(-2); // Agrega cero inicial si es necesario
          const day = ('0' + fechaHora.getDate()).slice(-2); // Agrega cero inicial si es necesario
          const hour = ('0' + fechaHora.getHours()).slice(-2); // Agrega cero inicial si es necesario
          const minute = ('0' + fechaHora.getMinutes()).slice(-2); // Agrega cero inicial si es necesario
          const second = ('0' + fechaHora.getSeconds()).slice(-2); // Agrega cero inicial si es necesario
      
          // Construye la cadena de fecha y hora en el formato deseado
          this.fechaHoraActual = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      }
      
        , 
      
        saveAdmEntregas() {

          
         /*  if (this.admentregas.Activo == false && this.auth.Rol == 5) {
            if(this.$msal.isAuthenticated()){
              this.$msal.signOut();
              localStorage.clear();
          this.$router.push("/Login");
            }
              
          }
          if (this.admentregas.Activo == true && this.auth.Rol == 5) {
            if(this.$msal.isAuthenticated()){
              this.$msal.signOut();
              localStorage.clear();
          this.$router.push("/Login");
            }
              
          } */
       if(this.auth.Rol == 8){
        this.admentregas.DocenteTrabajoDeGrado = this.selectedHit100.Usuario;
       }
          

           console.log(this.admentregas.EmailDocente);
           
            this.ss.storeAdmEntregas(this.admentregas).then(

              
                (result) => {
                    
                    console.log(result);
                   
                    let response = result.data;
                    this.esBotonDeshabilitado = false;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        },
                        
                    )
                   
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
               
                    this.$refs['frm-AdmEntregas2'].hide();
                    this.$refs['datatable-admentregas'].reload();

                    setTimeout(() => {
                      this.$swal.close();
                  },1000)
                    
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        updateEntregas2() {
        if(!this.entregas.DocumentoRevisadoDocente){
            this.$swal({
                title: "Error al Guardar, datos incompletos",
                text: "Por favor cargar el Documento en PDF",
                icon: "warning",
                dangerMode: true,
            })
            return;
        }
            this.esBotonDeshabilitado = true;
            this.$swal.fire({
              title: '<strong>Espere un momento su entrega se esta enviando</strong>',
              icon: 'info',
            });
            this.ss.updateEntregas2(this.entregas).then(

              
                (result) => {
                    
                    console.log(result);
                   
                    let response = result.data;
                    this.esBotonDeshabilitado = false,
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        },
                        
                    )
                   
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
               
                    this.$refs['frm-edit'].hide();
                    this.$refs['datatable-entregas'].reload();

                    setTimeout(() => {
                      this.$swal.close();
                  },1000)
                })
                .catch((error) => {
                    this.esBotonDeshabilitado = false,
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        updateEntregas3() {
          if(!this.entregas.DocumentoRevisadoTutor){
            this.$swal({
                title: "Error al Guardar, datos incompletos",
                text: "Por favor cargar el Documento en PDF",
                icon: "warning",
                dangerMode: true,
            })
            return;
        } 

            this.esBotonDeshabilitado = true;
            this.$swal.fire({
              title: '<strong>Espere un momento su entrega se esta enviando</strong>',
              icon: 'info',
            });
            this.ss.updateEntregas3(this.entregas).then(

              
                (result) => {
                    
                    console.log(result);
                   
                    let response = result.data;
                    this.esBotonDeshabilitado = false;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        },
                        
                    )
                   
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
               
                    this.$refs['frm-edit'].hide();
                    this.$refs['datatable-entregas'].reload();
                    setTimeout(() => {
                      this.$swal.close();
                  },1000)
                })
                .catch((error) => {
                    this.esBotonDeshabilitado = false,
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        updateEntregas4() {
         if(!this.entregas.DocumentoRevisadoRevisor){
            this.$swal({
                title: "Error al Guardar, datos incompletos",
                text: "Por favor cargar el Documento en PDF",
                icon: "warning",
                dangerMode: true,
            })
            return;
        } 
            this.esBotonDeshabilitado = true;
            this.$swal.fire({
              title: '<strong>Espere un momento su entrega se esta enviando</strong>',
              icon: 'info',
            });
            this.ss.updateEntregas4(this.entregas).then(

              
                (result) => {
                    
                    console.log(result);
                   
                    let response = result.data;
                    this.esBotonDeshabilitado = false;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        },
                        
                    )
                   
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
               
                    this.$refs['frm-edit'].hide();
                    this.$refs['datatable-entregas'].reload();

                    setTimeout(() => {
                      this.$swal.close();
                  },1000)
                })
                .catch((error) => {
                    this.esBotonDeshabilitado = false,
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        updateEntregas55() {
          if(!this.entregas.DocumentoRevisadoRevisor2){
            this.$swal({
                title: "Error al Guardar, datos incompletos",
                text: "Por favor cargar el Documento en PDF",
                icon: "warning",
                dangerMode: true,
            })
            return;
        } 
            this.esBotonDeshabilitado = true;
            this.$swal.fire({
              title: '<strong>Espere un momento su entrega se esta enviando</strong>',
              icon: 'info',
            });
            this.ss.updateEntregas55(this.entregas).then(

              
                (result) => {
                    
                    console.log(result);
                   
                    let response = result.data;
                    this.esBotonDeshabilitado = false;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        },
                        
                    )
                   
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
               
                    this.$refs['frm-edit'].hide();
                    this.$refs['datatable-entregas'].reload();

                    setTimeout(() => {
                      this.$swal.close();
                  },1000)
                })
                .catch((error) => {
                    this.esBotonDeshabilitado = false,
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteEntregas() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonColor: '#d33',
                buttons: true,
                dangerMode: true,
            })
            .then((result) => {
                if (result.isConfirmed) {
                        this.ss.destroyEntregas(this.entregas)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-entregas'].hide();
                                this.$refs['datatable-entregas'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        deleteAdmEntregas() {
          this.$swal({
              title: "Estas seguro que deseas eliminar?",
              text: "Esta accion es irreversible!",
              icon: "warning",
              showCloseButton: true,
              showCancelButton: true,
              cancelButtonColor: '#d33',
              buttons: true,
              dangerMode: true,
          })
          .then((result) => {
              if (result.isConfirmed) {
                      this.ss.destroyAdmEntregas(this.admentregas)
                          .then((result) => {
                              let response = result.data;
                              console.log(response);
                              this.$bvToast.toast(
                                  response.msg,
                                  {
                                      title: 'Correcto',
                                      variant: 'success',
                                      autoHideDelay: 5000
                                  }
                              )
                              this.$refs['frm-AdmEntregas2'].hide();
                              this.$refs['datatable-admentregas'].reload();
                          })
                          .catch(error => {
                              console.log(error);
                          })
                  } else {
                      //swal("Your imaginary file is safe!");
                  }
              });
      },

       
        loadFileWord(input) {
          this.isLoadingFile = true;
          input = event.target;
          if (input.files && input.files[0]) {
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]);
              var data = new FormData();
              data.append('File', input.files[0]);
              this.ss.uploadFile(data)
                  .then(result => {
                      if (result.data.success) {
                          this.$bvToast.toast(
                              result.data.msg,
                              {
                                  title: 'Correcto',
                                  variant: 'info',
                                  autoHideDelay: 5000
                              }
                          );
                          this.entregas.DocumentoWord = result.data.data;

                          
                      } else {
                          this.$bvToast.toast(
                              result.data.msg,
                              {
                                  title: 'Oops!',
                                  variant: 'danger',
                                  autoHideDelay: 5000
                              }
                          )
                      }
                      this.isLoadingFile = false;
                  })
                  .catch(error => {
                      console.log(error);
                      this.$bvToast.toast(
                          'Error subiendo el documento',
                          {
                              title: 'Oops!',
                              variant: 'danger',
                              autoHideDelay: 5000
                          }
                      )
                      this.isLoadingFile = false;
                  })
                  .catch(error => {
                      console.log(error);
                      this.$bvToast.toast(
                          'Error subiendo el documento',
                          {
                              title: 'Oops!',
                              variant: 'danger',
                              autoHideDelay: 5000
                          }
                      )
                      this.isLoadingFile = false;
                  });
          }
      },
        
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
              // Validación del tipo de archivo
              if (input.files[0].type !== 'application/pdf') {
                this.$bvToast.toast(
                  'El archivo seleccionado no es un Documento PDF',
                  {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000
                  }
                );
                this.isLoadingFile = false;
                return;
              }
          
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]);
              var data = new FormData();
              data.append('File', input.files[0]);
              this.ss.uploadFile(data)
                .then(result => {
                  if (result.data.success) {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Correcto',
                        variant: 'info',
                        autoHideDelay: 5000
                      }
                    );
                    this.entregas.Documento = result.data.data;
                  } else {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 5000
                      }
                    )
                  }
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                });
            }
          },

 loadFileAval(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
              // Validación del tipo de archivo
              if (input.files[0].type !== 'application/pdf') {
                this.$bvToast.toast(
                  'El archivo seleccionado no es un Documento PDF',
                  {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000
                  }
                );
                this.isLoadingFile = false;
                return;
              }
          
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]);
              var data = new FormData();
              data.append('File', input.files[0]);
              this.ss.uploadFile(data)
                .then(result => {
                  if (result.data.success) {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Correcto',
                        variant: 'info',
                        autoHideDelay: 5000
                      }
                    );
                    this.entregas.Aval = result.data.data;
                  } else {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 5000
                      }
                    )
                  }
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                });
            }
          },
 loadFileBitacora(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
              // Validación del tipo de archivo
              if (input.files[0].type !== 'application/pdf') {
                this.$bvToast.toast(
                  'El archivo seleccionado no es un Documento PDF',
                  {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000
                  }
                );
                this.isLoadingFile = false;
                return;
              }
          
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]);
              var data = new FormData();
              data.append('File', input.files[0]);
              this.ss.uploadFile(data)
                .then(result => {
                  if (result.data.success) {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Correcto',
                        variant: 'info',
                        autoHideDelay: 5000
                      }
                    );
                    this.entregas.Bitacora = result.data.data;
                  } else {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 5000
                      }
                    )
                  }
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                });
            }
          },

          modalBitacora() {
            this.$refs['frm-bitacora'].show();
        },

          loadFileDocumentoRevisadoDocente(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
              // Validación del tipo de archivo
              if (input.files[0].type !== 'application/pdf') {
                this.$bvToast.toast(
                  'El archivo seleccionado no es un Documento PDF',
                  {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000
                  }
                );
                this.isLoadingFile = false;
                return;
              }
          
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]);
              var data = new FormData();
              data.append('File', input.files[0]);
              this.ss.uploadFile(data)
                .then(result => {
                  if (result.data.success) {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Correcto',
                        variant: 'info',
                        autoHideDelay: 5000
                      }
                    );
                    this.entregas.DocumentoRevisadoDocente = result.data.data;
                  } else {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 5000
                      }
                    )
                  }
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                });
            }
          },
 loadFileDocumentoRevisadoTutor(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
              // Validación del tipo de archivo
              if (input.files[0].type !== 'application/pdf') {
                this.$bvToast.toast(
                  'El archivo seleccionado no es un Documento PDF',
                  {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000
                  }
                );
                this.isLoadingFile = false;
                return;
              }
          
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]);
              var data = new FormData();
              data.append('File', input.files[0]);
              this.ss.uploadFile(data)
                .then(result => {
                  if (result.data.success) {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Correcto',
                        variant: 'info',
                        autoHideDelay: 5000
                      }
                    );
                    this.entregas.DocumentoRevisadoTutor = result.data.data;
                  } else {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 5000
                      }
                    )
                  }
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                });
            }
          },
	
loadFileDocumentoRevisadoRevisor(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
              // Validación del tipo de archivo
              if (input.files[0].type !== 'application/pdf') {
                this.$bvToast.toast(
                  'El archivo seleccionado no es un Documento PDF',
                  {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000
                  }
                );
                this.isLoadingFile = false;
                return;
              }
          
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]);
              var data = new FormData();
              data.append('File', input.files[0]);
              this.ss.uploadFile(data)
                .then(result => {
                  if (result.data.success) {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Correcto',
                        variant: 'info',
                        autoHideDelay: 5000
                      }
                    );
                    this.entregas.DocumentoRevisadoRevisor = result.data.data;
                  } else {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 5000
                      }
                    )
                  }
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                });
            }
          },


          agregarVinetas() {
            const texto = this.entregas.ObservacionTutor;
            if (texto && !texto.startsWith('• ')) {
                this.entregas.ObservacionTutor = '• ' + texto;
            } else if (texto && texto.charAt(texto.length - 1) === '\n') {
                this.entregas.ObservacionTutor += '• ';
            }
          
            const lineas = texto.split('\n');
            this.filas = lineas.slice(0, 12); // Solo se muestran las primeras 12 líneas
            
            // Rellenar el arreglo con filas vacías si la longitud es menor que 12
            const diferencia = 12 - this.filas.length;
            if (diferencia > 0) {
              for (let i = 0; i < diferencia; i++) {
                this.filas.push('');
              }
            }


          },

          agregarVinetas2() {
            const texto = this.entregas.ObservacionDocente;
            if (texto && !texto.startsWith('• ')) {
                this.entregas.ObservacionDocente = '• ' + texto;
            } else if (texto && texto.charAt(texto.length - 1) === '\n') {
                this.entregas.ObservacionDocente += '• ';
            }
          
            const lineas = texto.split('\n');
            this.filas = lineas.slice(0, 12); // Solo se muestran las primeras 12 líneas
            
            // Rellenar el arreglo con filas vacías si la longitud es menor que 12
            const diferencia = 12 - this.filas.length;
            if (diferencia > 0) {
              for (let i = 0; i < diferencia; i++) {
                this.filas.push('');
              }
            }

            
          },

          agregarVinetas3() {
            const texto = this.entregas.ObservacionDocente;
            if (texto && !texto.startsWith('• ')) {
                this.entregas.ObservacionDocente = '• ' + texto;
            } else if (texto && texto.charAt(texto.length - 1) === '\n') {
                this.entregas.ObservacionDocente += '• ';
            }
          
            const lineas = texto.split('\n');
            this.filas = lineas.slice(0, 12); // Solo se muestran las primeras 12 líneas
            
            // Rellenar el arreglo con filas vacías si la longitud es menor que 12
            const diferencia = 12 - this.filas.length;
            if (diferencia > 0) {
              for (let i = 0; i < diferencia; i++) {
                this.filas.push('');
              }
            }

            
          },

          agregarVinetas4() {
            const texto = this.entregas.ObservacionRevisor;
            if (texto && !texto.startsWith('• ')) {
                this.entregas.ObservacionRevisor = '• ' + texto;
            } else if (texto && texto.charAt(texto.length - 1) === '\n') {
                this.entregas.ObservacionRevisor += '• ';
            }
          
            const lineas = texto.split('\n');
            this.filas = lineas.slice(0, 12); // Solo se muestran las primeras 12 líneas
            
            // Rellenar el arreglo con filas vacías si la longitud es menor que 12
            const diferencia = 12 - this.filas.length;
            if (diferencia > 0) {
              for (let i = 0; i < diferencia; i++) {
                this.filas.push('');
              }
            }

            
          },

          agregarVinetas5() {
            const texto = this.entregas.ObservacionRevisor2;
            if (texto && !texto.startsWith('• ')) {
                this.entregas.ObservacionRevisor2 = '• ' + texto;
            } else if (texto && texto.charAt(texto.length - 1) === '\n') {
                this.entregas.ObservacionRevisor2 += '• ';
            }
          
            const lineas = texto.split('\n');
            this.filas = lineas.slice(0, 12); // Solo se muestran las primeras 12 líneas
            
            // Rellenar el arreglo con filas vacías si la longitud es menor que 12
            const diferencia = 12 - this.filas.length;
            if (diferencia > 0) {
              for (let i = 0; i < diferencia; i++) {
                this.filas.push('');
              }
            }

            
          },
 loadFileDocumentoRevisadoRevisor2(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
              // Validación del tipo de archivo
              if (input.files[0].type !== 'application/pdf') {
                this.$bvToast.toast(
                  'El archivo seleccionado no es un Documento PDF',
                  {
                    title: 'Oops!',
                    variant: 'danger',
                    autoHideDelay: 5000
                  }
                );
                this.isLoadingFile = false;
                return;
              }
          
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]);
              var data = new FormData();
              data.append('File', input.files[0]);
              this.ss.uploadFile(data)
                .then(result => {
                  if (result.data.success) {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Correcto',
                        variant: 'info',
                        autoHideDelay: 5000
                      }
                    );
                    this.entregas.DocumentoRevisadoRevisor2 = result.data.data;
                  } else {
                    this.$bvToast.toast(
                      result.data.msg,
                      {
                        title: 'Oops!',
                        variant: 'danger',
                        autoHideDelay: 5000
                      }
                    )
                  }
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                })
                .catch(error => {
                  console.log(error);
                  this.$bvToast.toast(
                    'Error subiendo el documento',
                    {
                      title: 'Oops!',
                      variant: 'danger',
                      autoHideDelay: 5000
                    }
                  )
                  this.isLoadingFile = false;
                });
            }
          },
          draw() {
            window.$('.btn-datatable-Entregas').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showEntregas(data);
                const data2 = window.$(evt.target).attr('Estudiante'); // Obtener el valor de Estudiante
        
                this.showEstudianteE(data2);
            });
        
            window.$('.btn-datatable-AdmEntregas').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showAdmEntregas(data);
            });
        }
        
    },
    
    components: {
        dataTable,
        Loader,
    },
    provide: {
        PdfViewer: [Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, FormDesigner]
        } ,
    watch: {
        selectedHitEst(value) {
            this.entregas.Estudiante = value.Estudiante;
            this.entregas.EmailEstudiante = value.EmailEstudiante;

            this.entregas.Docente = value.DocenteTrabajoDeGrado;
            this.entregas.Tutor = value.Tutor;
            this.entregas.Revisor = value.Revisor;
            this.entregas.Revisor2 = value.Revisor2;
           
            this.entregas.EmailDocente = value.EmailDocente;
            this.entregas.UnidadAcademica = value.UnidadAcademica;
            this.entregas.EmailTutor = value.EmailTutor;
            this.entregas.EmailRevisor = value.EmailRevisor;
            this.entregas.EmailRevisor2 = value.EmailRevisor2;

            this.entregas.Gestion = value.Gestion;
            this.entregas.Especialidad = value.Especialidad;

    

          
            
            //console.log(value);
        },
        selectedHit100(value) {
          this.admentregas.EmailDocente= value.CorreoEmi;
          //console.log(value);
      }, 
       /*  selectedHitDoc(value) {
            this.entregas.Docente= value.CorreoEmi;
            //console.log(value);
        },
         */
        selectedHitTutor(value) {
            this.entregas.Tutor = value.CorreoEmi;
            //console.log(value);
        },
        selectedHitRevisor(value) {
            this.entregas.Revisor = value.CorreoEmi;
            //console.log(value);
        },
        // selectedHitRevisor2(value) {
        //     this.entregas.Revisor2 = value.CorreoEmi;
        //     //console.log(value);
        // },
        
       },
    filters: {
        moment(date) {
          return moment(date).format("DD-MM-YYYY");
        },
      },
    mounted() {

        var usuario = JSON.parse(localStorage.getItem('usuario'));
        console.log(usuario);
        

        if (!usuario) {
          this.$router.push('/Login');
        }else{
         this.obtenerFechaHoraActual();
          setInterval(this.obtenerFechaHoraActual, 1000);  
            this.getFase();
            this.auth = usuario;

            //this.entregas2.Estudiante=this.showEstudiante(this.auth.id);
            this.showEstudiante2(this.auth.id);
        
            //console.log(this.entregas2);

            
            this.getDocente(); 
            this.getUsuario();
            this.getEstudiante();
            this.getGestion();
            this.getEspecialidad();
            
        }
    }
};
