
<template>
    <v-list nav dense>
        <v-list-item-group>

             

            
           <router-link
                class="black--text text--accent-4"
                to="Profile"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">mdi-account-card-details</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <abbr title="Perfil personal del Usuario">
                             <v-list-item-title>{{
                            $t("nav.Profile")
                        }}</v-list-item-title>
                        </abbr>

                       
                    </v-list-item-content>
                </v-list-item>
            </router-link>
          
             <router-link
             v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 8 || auth.Rol == 10 || auth.Rol == 9"
                class="black--text text--accent-4"
                to="TipoReporteVer"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">mdi-file-chart</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <abbr title="Reporte que genera el Sistema">
                            <v-list-item-title>{{
                            $t("nav.TipoReporteVer")
                        }}</v-list-item-title>
                        </abbr>

                        
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            
            <!--   <router-link
            
               v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 8 || auth.Rol == 10 || auth.Rol == 3  || auth.Rol == 4 || auth.Rol == 7"
                class="black--text text--accent-4"
                to="Bitacora"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">mdi-clipboard-text</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="El sistema genera la bitacora correspondiente para cada fase del Trabajo de Grado">
                             <v-list-item-title>{{
                            $t("Generar Bitacora")
                        }}</v-list-item-title>
                        </abbr>
                       
                    </v-list-item-content>
                </v-list-item>
            </router-link>
            
             <router-link
             onClick="window.location.reload();"
               v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 8 || auth.Rol == 10 || auth.Rol == 3  || auth.Rol == 4 || auth.Rol == 7"
                class="black--text text--accent-4"
                to="Aval"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">mdi-clipboard-check</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="El sistema genera el Aval correspondiente para cada fase del Trabajo de Grado">
                             <v-list-item-title onClick="window.location.reload();">{{
                            $t("Generar Aval")
                        }}</v-list-item-title>
                        </abbr>
                       
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

             <!--   <router-link
                onClick="window.location.reload();"
               v-if=" auth.Rol == 5"
                class="black--text text--accent-4"
                to="CartaInvitacionTutoria"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">mdi-clipboard-check</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="El sistema genera Carta de Invitación para Tutoría por parte del Estudiante">
                             <v-list-item-title onClick="window.location.reload();">{{
                            $t("Generar Carta de Invitación ")
                        }}</v-list-item-title>
                        </abbr>
                       
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->
          <!--    <router-link
                onClick="window.location.reload();"
               v-if=" auth.Rol == 3"
                class="black--text text--accent-4"
                to="CartaAceptacionTutoria"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">mdi-library-books</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="El sistema genera Carta de Aceptación para Tutoría por parte del Docente Tutor">
                             <v-list-item-title onClick="window.location.reload();">{{
                            $t("Generar Carta de Aceptación")
                        }}</v-list-item-title>
                        </abbr>
                       
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

             <router-link
             onClick="window.location.reload();"
               v-if="auth.Rol == 1 || auth.Rol == 2 "
                class="black--text text--accent-4"
                to="ConsultaAval"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">mdi-code-greater-than-or-equal</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="Consulta de Avales cargados en el sistema por los Docentes">
                             <v-list-item-title >{{
                            $t("Consulta de Avales")
                        }}</v-list-item-title>
                        </abbr>
                       
                    </v-list-item-content>
                </v-list-item>
            </router-link>
           
        <v-list-group>
                <template v-slot:activator>
                    <v-list-item-icon>
                        <v-icon color="orange">mdi-cogs</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                         <abbr title="Son todas las funcionalidades del sistema que son parametrizables ">
                            <v-list-item-title v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 8 || auth.Rol == 10 ">Paramétricas</v-list-item-title>
                              <v-list-item-title v-if="auth.Rol == 3 || auth.Rol == 4 || auth.Rol == 5 || auth.Rol == 6 || auth.Rol == 7 || auth.Rol == 9">Acciones</v-list-item-title>
                        </abbr>
                      
                    </v-list-item-content>
                </template>

                <router-link
                class="black--text text--accent-4"
                to="Usuario"
                active-class="blue--text text--accent-4"
                    >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">mdi-account-multiple</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="Los usuarios que tienen acceso al Sistema">
                            <v-list-item-title>{{
                            $t("Listado Usuario")
                        }}</v-list-item-title>
                        </abbr>
                        
                    </v-list-item-content>
                </v-list-item>
                </router-link>
            
            
                 <router-link
                  v-if="auth.Rol == 1 || auth.Rol == 2  "
                class="black--text text--accent-4"
                to="TipoMiembro"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">fa-user-plus</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>

                         <abbr title="Es el tipo de miembro que será asignado al tribunal">
                            <v-list-item-title>{{
                            $t("Tipo Miembro")
                            }}</v-list-item-title>
                        </abbr>
                        
                    </v-list-item-content>
                </v-list-item>
            </router-link>

               <router-link
              v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 8 || auth.Rol == 10 || auth.Rol == 3  || auth.Rol == 4 || auth.Rol == 7 || auth.Rol == 6 || auth.Rol == 9"
                class="black--text text--accent-4"
                to="Estudiante"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">fas fa-user-graduate</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content >
                         <abbr title="Usuarios de rol Estudiante que muestra todos sus datos requeridos para el Trabajo de Grado">
                            <v-list-item-title v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 8 || auth.Rol == 10 || auth.Rol == 6 || auth.Rol == 9">{{$t("Estudiante")}}</v-list-item-title>
                            <v-list-item-title v-if=" auth.Rol == 3  || auth.Rol == 4 || auth.Rol == 7">{{$t("Tutorados")}}</v-list-item-title>
                        
                        </abbr>
                      
                    </v-list-item-content>
                </v-list-item>
            </router-link>

             <!-- <router-link
              v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 8 || auth.Rol == 10 || auth.Rol == 6"
                class="black--text text--accent-4"
                to="MiembrosTribunal"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">fa fa-gavel</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="Se asigna a los usuarios que son Miembros del Tribunal">
                            <v-list-item-title>{{
                            $t("Miembros Tribunal")
                        }}</v-list-item-title>
                        </abbr>
                        
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

           
              


            

           

    

            <router-link
             v-if="auth.Rol == 1"
                class="black--text text--accent-4"
                to="UnidadAcademica"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">fa fa-university</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="Las diferentes Unidades Academicas de la Escuela Militar de Ingeniería">
                             <v-list-item-title>{{
                            $t("Unidad Académica")
                        }}</v-list-item-title>
                        </abbr>
                       
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
             v-if="auth.Rol == 1"
                class="black--text text--accent-4"
                to="Rol"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">mdi-key</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="Roles de usuario del Sistema">
                             <v-list-item-title>{{
                            $t("nav.Rol")
                        }}</v-list-item-title>
                        </abbr>
                       
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            
            
           

            

        
                <router-link
                 v-if="auth.Rol == 1 || auth.Rol == 2 "
                class="black--text text--accent-4"
                to="Fase"
                active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon color="#003F8A">fab fa-stack-overflow</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                             <abbr title="Las diferentes fases del Trabajo de Grado">
                                <v-list-item-title>{{
                                $t("nav.Fase")
                            }}</v-list-item-title>
                        </abbr>
                            
                        </v-list-item-content>
                    </v-list-item>
                </router-link>

          
                 <router-link
                  v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 8 || auth.Rol == 6 || auth.Rol == 3 || auth.Rol == 4 || auth.Rol == 7 || auth.Rol == 9 || auth.Rol == 5 || auth.Rol == 10"
                    class="black--text text--accent-4"
                    to="Proyecto"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon color="#003F8A">fa-solid fa-book</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                             <abbr title="Títulos de los proyectos propuestos por los Estudiantes">
                                <v-list-item-title>{{
                                $t("Proyecto")
                            }}</v-list-item-title>
                        </abbr>
                            
                        </v-list-item-content>
                    </v-list-item>
                </router-link>


                <router-link
                  v-if="auth.Rol == 5 "
                    class="black--text text--accent-4"
                    to="Carta"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon color="#003F8A">fa-solid fa-book</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                             <abbr title="Nombramiento del Tutor">
                                <v-list-item-title>{{
                                $t("Nombramiento del Tutor")
                            }}</v-list-item-title>
                        </abbr>
                            
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                

                <router-link
                 v-if="auth.Rol == 1"
                    class="black--text text--accent-4"
                    to="Gestion"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon color="#003F8A">mdi-chart-bubble</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                             <abbr title="Es el periodo de gestión durantes los semestres academicos">
                                <v-list-item-title>{{
                                $t("Gestión")
                            }}</v-list-item-title>
                        </abbr>
                            
                        </v-list-item-content>
                    </v-list-item>
                </router-link>

                <router-link
                 v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 8 || auth.Rol == 10 || auth.Rol == 6"
                    class="black--text text--accent-4"
                    to="AreaInvestigacion"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon color="#003F8A">mdi mdi-server-network</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                             <abbr title="Es la Rama o campo de estudio en el cual se realiza la investigación">
                                <v-list-item-title>{{
                                $t("nav.AreaInvestigacion")
                            }}</v-list-item-title>
                        </abbr>
                            
                        </v-list-item-content>
                    </v-list-item>
                </router-link>

                <router-link
                 v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 8 || auth.Rol == 10 || auth.Rol == 6 "
                    class="black--text text--accent-4"
                    to="LineaInvestigacion"
                    active-class="blue--text text--accent-4"
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon color="#003F8A">mdi mdi-server-plus</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                             <abbr title="Es el enfoque que abarca el desarrollo de la investigación">
                                 <v-list-item-title>{{
                                $t("nav.LineaInvestigacion")
                            }}</v-list-item-title>
                        </abbr>
                           
                        </v-list-item-content>
                    </v-list-item>
                </router-link>


            <router-link
              v-if="auth.Rol == 1 || auth.Rol == 2   || auth.Rol == 3 || auth.Rol == 4 || auth.Rol == 7 || auth.Rol == 5 || auth.Rol == 8 || auth.Rol == 10 "
                class="black--text text--accent-4"
                to="Entregas"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">mdi mdi-approval</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="Es la entrega de avances del Trabajo de Grado que realiza el Estudiante">
                            <v-list-item-title>{{
                            $t("nav.Entregas")
                        }}</v-list-item-title>
                        </abbr>
                        
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
                v-if="auth.Rol == 1 || auth.Rol == 2 || auth.Rol == 8 || auth.Rol == 10 || auth.Rol == 3 || auth.Rol == 4 || auth.Rol == 7 || auth.Rol == 5"
                class="black--text text--accent-4"
                to="Defensas"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">mdi-book-open-variant</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="Permite calificar y realizar observaciones a las defensas realizadas por los Estudiantes">
                              <v-list-item-title>{{
                            $t("Defensas")
                        }}</v-list-item-title>
                        </abbr>
                      
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
             v-if="auth.Rol == 1"
                class="black--text text--accent-4"
                to="Semestre"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">mdi-apps</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="Semestre de la Carrera">
                             <v-list-item-title>{{
                            $t("nav.Semestre")
                        }}</v-list-item-title>
                        </abbr>
                       
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
             v-if="auth.Rol == 1"
                class="black--text text--accent-4"
                to="Especialidad"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">mdi-checkbox-multiple-blank</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="Las diferentes especialidades de la Escuela Militar de Ingeniería">
                              <v-list-item-title>{{
                            $t("nav.Especialidad")
                        }}</v-list-item-title>
                        </abbr>
                      
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <router-link
             v-if="auth.Rol == 1"
                class="black--text text--accent-4"
                to="NivelAcademico"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">mdi-arrange-bring-to-front</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="Nivel Academico el cual cuenta la Institución">
                             <v-list-item-title>{{
                            $t("nav.NivelAcademico")
                        }}</v-list-item-title>
                        </abbr>
                       
                    </v-list-item-content>
                </v-list-item>
            </router-link>

           <!--  <router-link
             v-if="auth.Rol == 1 || auth.Rol == 2 "
                class="black--text text--accent-4"
                to="TipoReporte"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">mdi-note-text</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="Es el tipo de reporte que genera el Sistema">
                             <v-list-item-title>{{
                            $t("nav.TipoReporte")
                        }}</v-list-item-title>
                        </abbr>
                       
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <router-link
             v-if="auth.Rol == 1 || auth.Rol == 2  || auth.Rol == 8"
                class="black--text text--accent-4"
                to="TipoInforme"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">mdi-library-books</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="Tipo de informe que posteriormente sera cargado o insertado en el sistema">
                             <v-list-item-title>{{
                            $t("TipoInforme")
                        }}</v-list-item-title>
                        </abbr>
                       
                    </v-list-item-content>
                </v-list-item>
            </router-link>

              <router-link
             v-if="auth.Rol == 1 || auth.Rol == 2  || auth.Rol == 3 || auth.Rol == 4 || auth.Rol == 7 || auth.Rol == 5 || auth.Rol == 9 || auth.Rol == 10"
                class="black--text text--accent-4"
                to="Documentacion"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">mdi-book-plus</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="Son los informes del Trabajo de Grado que se carga en el sistema">
                            <v-list-item-title>{{
                            $t("Documentación")
                        }}</v-list-item-title>
                        </abbr>
                        
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            

            </v-list-group>
        <!--<button onClick="window.location.reload();">Refresh Page</button>-->

      
        <router-link
        v-if="auth.Rol != 9"
                class="black--text text--accent-4"
                to="/Main"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    
                    <v-list-item-icon>
                        <v-icon color="#003F8A">fa fa-home</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="Página de Inicio del Sistema">
                             <v-list-item-title>{{
                            $t("Ir a Página de Inicio")
                        }}</v-list-item-title>
                        </abbr>
                       
                    </v-list-item-content>
                </v-list-item>
            </router-link>


          <router-link
          v-if="auth.Rol != 9"
                class="black--text text--accent-4"
                to="ManualUsuario"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">fa fa-book</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="Manual de Usuario">
                             <v-list-item-title>{{
                                $t("Manual de Usuario")
                        }}</v-list-item-title>
                        </abbr>
                       
                    </v-list-item-content>
                </v-list-item>
            </router-link>  


            <router-link
          v-if="auth.Rol != 9"
                class="black--text text--accent-4"
                to="ManualActividad"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="#003F8A">fa fa-book</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                         <abbr title="Manual de Usuario">
                             <v-list-item-title>{{
                                $t("Manual Secuencia de Actvs.")
                        }}</v-list-item-title>
                        </abbr>
                       
                    </v-list-item-content>
                </v-list-item>
            </router-link>  
            
        </v-list-item-group>
    </v-list>
</template>

<script>

export default {
    name: "MenuVuetify",
    
    data() {
        
        return {
            msg: "MenuVuetify",
            auth : null,
        };
    },
   
    methods: {
      
  },
 
      mounted() {
        var usuario = JSON.parse(localStorage.getItem('usuario'));
        if (!usuario) {
            this.$router.push('/Main');
        }else {
           
            this.auth = usuario;
           
        }
    }
}

</script>